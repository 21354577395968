import React from "react";
import { Typography, Divider } from "antd";
import { CloudOutlined, FileSyncOutlined } from "@ant-design/icons";
import "./PlantingWindow.css";

const { Text } = Typography;

const RainfallAverage = ({ last15Days, lastReport }) => (
  <div className="plantingWindowContainer">
    <h2 className="plantingWindowTitle">Total das chuvas</h2>
    <Divider className="dividerCustom" />
    <div className="elementFlexVertical">
      <div className="infoSection">
        <CloudOutlined className="cloudIconLarge" />
        <div>
          <Text className="textInfoTitle">Últimos 15 dias</Text>
          <br />
          <Text className="textInfo">{last15Days.toFixed(2)} mm</Text>
        </div>
      </div>

      <div className="infoSection">
        <FileSyncOutlined className="cloudIconLarge" />
        <div>
          <Text className="textInfoTitle">Último relatório</Text>
          <br />
          <Text className="textInfo">
            {lastReport ? `${lastReport.toFixed(2)} mm` : "Não disponível"}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default RainfallAverage;
