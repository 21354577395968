import React, { useState, useEffect } from "react";
import { Card, Typography, Select, Flex, Skeleton } from "antd";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const { Option } = Select;
const { Text } = Typography;

dayjs.locale("pt-br");

const TemperatureChart = ({ data }) => {
  // Define state and effects at the top level
  const [selectedYear, setSelectedYear] = useState(null);
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedData = [...data].sort((a, b) => b.year - a.year);
      const latestYearData = sortedData[0];
      setSelectedYear(latestYearData.year);
      updateChartData(latestYearData);
    }
  }, [data]);

  const updateChartData = (yearData) => {
    const series = [
      {
        name: "Min",
        data: yearData.data.map((monthData) => monthData.temp_min),
      },
      {
        name: "Méd.",
        data: yearData.data.map((monthData) => monthData.temp_avg),
      },
      {
        name: "Max",
        data: yearData.data.map((monthData) => monthData.temp_max),
      },
    ];
    setChartSeries(series);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    const selectedYearData = data.find((yrData) => yrData.year === year);
    if (selectedYearData) {
      updateChartData(selectedYearData);
    }
  };

  if (!data || data.length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;
  }

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: selectedYear
        ? data
            .find((yrData) => yrData.year === selectedYear)
            .data.map((monthData) => dayjs(monthData.date).format("MMM"))
        : [],
    },
    yaxis: {
      max: 45,
      min: 0,
      tickAmount: 10,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
      },
    },
    grid: {
      show: true,
      borderColor: "#e9e9e9",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
  };

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#B64CFF",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              maxWidth: "calc(100% - 100px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Gráficos de temperatura
          </Text>
          <Flex align="center">
            <div style={{ width: "8px" }}></div>
            <Select
              value={selectedYear}
              style={{ width: "100%" }}
              onChange={handleYearChange}
            >
              {data.map((yearData) => (
                <Option key={yearData.year} value={yearData.year}>
                  {yearData.year}
                </Option>
              ))}
            </Select>
          </Flex>
        </div>
      }
      bordered={true}
      style={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        flex: 1,
      }}
    >
      <ReactApexChart
        options={options}
        series={chartSeries}
        type="bar"
        height={"320px"}
      />
    </Card>
  );
};

export default TemperatureChart;
