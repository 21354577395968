import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DashboardHeader from "../components/dashboard/Header";
import FarmInfo from "../components/dashboard/FarmInfo";
import HistoricalParams from "../components/dashboard/HistoricalParameters";
import MapboxWithFilters from "../components/dashboard/MapboxWithFilters";
import RainfallToPlantHealthChart from "../components/dashboard/RainfallToPlantHealthChart";
import RainfallChart from "../components/dashboard/RainfallChart";
import TemperatureChart from "../components/dashboard/TemperatureChart";
import WindSpeedChart from "../components/dashboard/WindSpeedChart";
import {
    fetchTicketData, fetchMonthlyWeatherData, fetchNDVI,
} from "../components/api/api";
import dataToRainfallNDVI from "../components/dashboard/functions/DataToRainfallNDVI";
import generateTemperatureData from "../components/dashboard/functions/GenerateTemperatureData";
import formatDate from "../components/dashboard/functions/formatDate";

import "./Reports.css";
import ESGCards from "../components/dashboard/ESGCards";
import LoadingPage from "../components/dashboard/LoadingPage";
import getStatus from "../components/dashboard/Status";

function AuraReport() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [monthlyWeatherData, setMonthlyWeatherData] = useState(null);
    const [NDVIScores, setNDVIScores] = useState(null);
    const [status, setRiskCalculationStatus] = useState(null);
    const [coords, setCoords] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketData = await fetchTicketData(id);
                setCoords([ticketData.center_coords.lat, ticketData.center_coords.long]);
                setRiskCalculationStatus(ticketData.risk_calculation_status);
                setData(ticketData);
            } catch (error) {
                console.error("Error loading ticket data:", error);
            }

            try {
                const ndviScores = await fetchNDVI(id, "ndvi", [], "monthly", "monthly");

                if (ndviScores.weather_data) {
                    setMonthlyWeatherData(ndviScores.weather_data);
                }

                setNDVIScores(ndviScores.reports);
            } catch (error) {
                console.error("Error loading NDVI data:", error);
            }
            setIsLoading(false);
        };

        if (id) {
            if (data == null) {
                fetchData();
            } else if (status === 'calculating' || status == null) {
                const timeout = setTimeout(() => {
                    fetchData();
                }, 20000);

                return () => clearTimeout(timeout);
            }
        }
    }, [id, data]);

    useEffect(() => {
        setIsLoading(true);
    }, [id]);

    useEffect(() => {
        setData(data);
    }, [data]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleStatusChange = async (newStatus) => {
        setData({ ...data, consultation_status: newStatus });
    };

    if (isLoading) {
        return <LoadingPage />;
    }

    const rainfallData = monthlyWeatherData ? monthlyWeatherData?.rainfall_sum : [];
    const ndviData = NDVIScores || [];
    const temperatureData = generateTemperatureData(monthlyWeatherData);
    
    return (
        <div className="reportContainer">
            <div className="elementFlexVertical">
                <div className="elementFlexHorizontal" style={{ minHeight: "64px" }}>
                    <DashboardHeader ticket={data} onStatusChange={handleStatusChange} />
                </div>

                <div className="elementFlexHorizontal" style={{ maxHeight: "556px" }}>
                    <div className="elementFlexVertical" style={{ flex: 2 }}>
                        {(data?.farm_data) && (
                            <FarmInfo
                                farmerName={data?.farm_data?.farmer?.farmer_name ?? ''}
                                farmName={data?.farm_data?.farmer?.farmer_name ?? ''}
                                farmLocation={`${data?.farm_data?.kml_document?.kml_polygon?.municipality}, ${data?.farm_data?.kml_document?.kml_polygon?.state}`}
                                cropType={data.farm_data.crop_type}
                                hectares={data.land_size.hectares}
                                status={getStatus(data)}
                                ticketNumber={data.id}
                                subscriber={data.subwriter}
                                date={formatDate(data.created_at)}
                                mapImg={data.farm_data.kml_document.kml_polygon.map_html}
                                riskScore={data.risk_score}
                                totalClassifications={data.total_classifications}
                            />
                        )}
                    </div>

                    <div className="elementFlexVertical" style={{ flex: 6 }}>
                        <MapboxWithFilters id={data?.id} coords={coords} style={{ height: "100%" }} />
                    </div>
                </div>
                <div className="elementFlexHorizontal">
                    {(data) && (
                        <ESGCards
                            data={data?.risk_classification_records}
                            isLoading={data?.risk_score ? data?.risk_score : null}
                            sicar_overlaps={data?.sicar_overlaps}
                        />
                    )}
                </div>

                <div className="elementFlexHorizontal">
                    {(data) && (
                        <HistoricalParams
                            data={data?.risk_classification_records}
                            isCalculating={data?.risk_classification_records?.length < data?.total_classifications}
                        />
                    )}
                </div>

                <div className="elementFlexHorizontal">
                    <RainfallChart data={rainfallData} />
                </div>

                <div className="elementFlexVertical">
                    <RainfallToPlantHealthChart data={dataToRainfallNDVI(rainfallData, ndviData)} />
                </div>

                <div className="elementFlexHorizontal">
                    <div className="elementFlexHorizontal">
                        <TemperatureChart data={temperatureData} />
                    </div>
                    <div className="elementFlexHorizontal">
                        <WindSpeedChart data={monthlyWeatherData?.windspeed_max} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuraReport;
