function senseTempWindspeed(weatherInfo) {
    if (weatherInfo) {
      const {
        temperature_avg,
        temperature_max,
        temperature_min,
        windspeed_max,
        dates
      } = weatherInfo;
  
      // Check if all properties are defined and are arrays
      if (
        Array.isArray(temperature_avg) &&
        Array.isArray(temperature_max) &&
        Array.isArray(temperature_min) &&
        Array.isArray(windspeed_max) &&
        Array.isArray(dates)
      ) {
        const temperatures = {};
        const windSpeeds = {};
  
        dates.forEach((date, index) => {
          temperatures[date] = {
            min: temperature_min[index],
            med: temperature_avg[index],
            max: temperature_max[index]
          };
  
          windSpeeds[date] = {
            max: windspeed_max[index]
          };
        });
  
        return { temperatures, windSpeeds };
      }
    }
  
    // Return empty objects if data is not properly structured
    return { temperatures: {}, windSpeeds: {} };
  }
  
  export default senseTempWindspeed;
  