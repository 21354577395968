import React from "react";
import { Typography, Divider, Tooltip } from "antd";
import "./PlantingWindow.css";
import {
  ScanOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  FileExcelFilled,
  InfoCircleOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text } = Typography;

function zarcTag(zarcComplianceData, dateSowing) {
  if (!dateSowing)
    return <div style={{ display: "flex", gap: "4px" }}>
      <div style={{minWidth: 56}}>{`${zarcComplianceData?.group}: `}</div>
      <ScanOutlined style={{ color: "#FFA940" }} />
      <Text className="zarcTextInfo">
        Plantio não detectado
      </Text>
    </div>

    if (zarcComplianceData?.zarc_compliance === true)
      return <div style={{ display: "flex", gap: "4px" }}>
        <div style={{minWidth: 56}}>{`${zarcComplianceData?.group}: `}</div>
        <CheckCircleFilled style={{ color: "#52C41A" }} />
        <Text className="zarcTextInfo">
          Plantando conforme ZARC
        </Text>
      </div>

    if (zarcComplianceData?.zarc_compliance === false)
      return <div style={{ display: "flex", gap: "4px" }}>
        <div style={{minWidth: 56}}>{`${zarcComplianceData?.group}: `}</div>
        <CloseCircleFilled style={{ color: "#FF4D4F" }} />
        <Text className="zarcTextInfo">
          Plantando fora do ZARC
        </Text>
      </div>

    return <div style={{ display: "flex", gap: "4px" }}>
      <div style={{minWidth: 56}}>{`${zarcComplianceData?.group}: `}</div>
      <FileExcelFilled style={{ color: "#FF4D4F" }} />
      <Text className="zarcTextInfo">
        ZARC não encontrado
      </Text>
    </div>
}

const PlantingWindow = ({
  zarcCompliance,
  dateSowing,
  dateHarvest,
}) => (
  <div className="plantingWindowContainer">
    <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
      <h2 className="plantingWindowTitle">Janela da safra</h2>
      <Tooltip
          title="As janelas do ZARC são determinadas por grupo de semente, calculamos o grupo pertencente ao final do monitoramento."
          overlayStyle={{ maxWidth: '320px' }}
      >
          <InfoCircleOutlined style={{cursor: "pointer", fontSize: 20}} />
      </Tooltip>
    </div>
    <Divider className="dividerCustom" />
    <div className="elementFlexVertical">
      <div className="zarcSection">
        <Text className="zarcTitle">Janela do ZARC</Text>
        {zarcCompliance.length > 0 ? (
          zarcCompliance.map((item, index) => (
            <div key={index} className="zarcInfoLine">
              {zarcTag(item, dateSowing)}
              <Tooltip
                title={
                  <>
                    Janela {item.group}:<br />
                    {new Date(item.zarc_start_date).toLocaleDateString('pt-BR')} - {new Date(item.zarc_end_date).toLocaleDateString('pt-BR')}
                    {
                      item.gap_1_start && item.gap_1_end && (
                        <>
                          <br />Intervalo 1:<br />
                          {`${new Date(item.gap_1_start).toLocaleDateString('pt-BR')} - ${new Date(item.gap_1_end).toLocaleDateString('pt-BR')}`}
                        </>
                      )
                    }
                    {
                      item.gap_2_start && item.gap_2_end && (
                        <>
                          <br />Intervalo 2:<br />
                          {`${new Date(item.gap_2_start).toLocaleDateString('pt-BR')} - ${new Date(item.gap_2_end).toLocaleDateString('pt-BR')}`}
                        </>
                      )
                    }
                    <br />

                  </>
                }
                overlayStyle={{ maxWidth: '320px' }}
              >
                <CalendarOutlined style={{ cursor: "pointer", fontSize: 16, color: "#9236D9" }} />
              </Tooltip>
            </div>
          ))
        ) : (
          <Text className="textInfo">Não disponível</Text>
        )}
      </div>
      <div className="infoSection">
        <img src="/icons/water.svg" alt="Menu" className="cloudIconLarge" />
        <div>
          <Text className="textInfoTitle">Data estimada de plantio</Text>
          <br />
          <Text className="textInfo">
            {(dateSowing) ?
              `${dayjs(dateSowing).format('DD/MM/YYYY')}` : "Não disponível"}
          </Text>
        </div>
      </div>

      <div className="infoSection">
        <img
          src="/icons/inventory.svg"
          alt="Inbox"
          className="cloudIconLarge"
        />
        <div>
          <Text className="textInfoTitle">Data estimada de colheita</Text>
          <br />
          <Text className="textInfo">
            {(dateHarvest) ?
              `${dayjs(dateHarvest).format('DD/MM/YYYY')}` : "Não disponível"}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default PlantingWindow;
