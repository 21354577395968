import React from "react";
import { fetchVisionTickets } from "../components/api/api";
import TicketListingVision from "../components/TicketListingVision";

function Vision() {
  return (
    <div>
      <TicketListingVision fetchTickets={fetchVisionTickets}/>
    </div>
  );
}

export default Vision;
