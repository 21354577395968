import { React, useState } from "react";
import "./HelpPopout.css";
import { Button, Divider, Input, Segmented, Select, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import emailjs from "@emailjs/browser";
import { parseCookies } from "nookies";



const HelpPopout = ({ closePopover  }) => {
    const [option, setOption] = useState("Problema/Bug");
    const [selectedPage, setSelectedPage] = useState(undefined);
    const [description, setDescription] = useState("");
    const [loadingSendEmail, setLoadingSendEmail] = useState(false);

    const handleCancel = () => {
        setOption('Problema/Bug');
        setSelectedPage(undefined);
        setDescription('');
        
        closePopover();
    };

    const handleSubmit = async () => {
        if (selectedPage === undefined || description === "") {
            message.error("Preencha todos os campos");
            return;
        }

        setLoadingSendEmail(true);

        const cookies = parseCookies();

        const templateParams = {
            from_name: cookies["email"],
            type: option,
            page: selectedPage,
            description: description
        }

        try {
            await emailjs.send(
                "service_ml87a6n",
                "template_90fdemm",
                templateParams,
                "lPEIyc-nNG2q3_ATM"
            );
            
            message.success("Obrigado pelo contato");
        }
        catch(err) {
            console.log("error: ", err);
        }

        setLoadingSendEmail(false);
    };

    const textOptions = {
        "Problema/Bug": {
            question: "Qual parte do produto você deseja suporte?",
            detail: "Por favor, descreva o que ocorreu:",
            placeholder: "Breve descrição do problema e o que você fez antes dele ocorrer."
        },
        "Sugestão": {
            question: "Sobre qual parte do produto você deseja dar uma sugestão?",
            detail: "Por favor, descreva a sua sugestão:",
            placeholder: "De detalhes do que nós podemos fazer para melhor suprir suas necessidades com o produto."
        },
        "Ajuda": {
            question: "Com qual parte do produto você necessita de ajuda?",
            detail: "Por favor, descreva a sua dúvida:",
            placeholder: "De detalhes do que nós podemos fazer para melhor suprir suas necessidades com o produto."
        },
    }

    return (
        <div className="helpPopoutCard">
            <div className="helpPopoutHeader">Solicitar suporte</div>
            <Divider style={{margin: "16px"}}/>
            <div className="helpPopoutContent">
                <div>Qual tipo de suporte deseja solicitar?</div>
                <Segmented
                    defaultValue="Problema/Bug"
                    value={option}
                    className="helpPopoutSegmentedSelector"
                    onChange={(value) => setOption(value)}
                    options={['Problema/Bug', 'Sugestão', 'Ajuda']}
                />
                <div>
                    {textOptions[option].question}
                </div>
                <Select
                    value={selectedPage}
                    placeholder="Selecione uma opção"
                    className="helpPopoutServiceSelect"
                    onChange={value => setSelectedPage(value)}
                    options={[
                        { value: 'Home page', label: 'Home page' },
                        { value: 'Página de consulta', label: 'Página de consulta' },
                        { value: 'Consultas Aura', label: 'Consultas Aura' },
                        { value: 'Dashboard Aura', label: 'Dashboard Aura' },
                        { value: 'Consultas Sense', label: 'Consultas Sense' },
                        { value: 'Dashboard Sense', label: 'Dashboard Sense' },
                        { value: 'Central de finanças', label: 'Central de finanças' },
                        { value: 'Outro', label: 'Outro' },
                    ]}
                />
                <div>
                    {textOptions[option].detail}
                </div>
                <Input.TextArea
                    value={description}
                    placeholder={textOptions[option].placeholder}
                    rows={6}
                    onChange={e => setDescription(e.target.value)}
                />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Button
                        className="helpPopoutCalcelButton"
                        onClick={handleCancel}
                    >
                        Cancelar
                    </Button>
                    
                    <Button 
                        className="helpPopoutSendButton"
                        type="primary"
                        onClick={handleSubmit}
                        loading={loadingSendEmail}
                        icon={<SendOutlined/>}
                    >
                        Enviar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default HelpPopout;
