import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  fetchTicketData,
  fetchWeatherReports,
  fetchAuraInsights,
} from "../../components/api/api";
import "./Aura.css";
import AuraHeader from "./AuraHeader";
import LoadingPage from "../../components/dashboard/LoadingPage";
import { Tabs } from "antd";
import AuraESGCards from "../../components/dashboard/AuraESGCards";
import AuraHistoricalParameters from "./AuraHistoricalParameters";
import RainfallChart from "../../components/dashboard/RainfallChart";
import AuraInsights from "./AuraInsights";
import ClimaticRisk from "../../components/dashboard/ClimaticRisk";
import AuraFieldAnalysis from "./AuraFieldAnalysis";
import AuraMap from "../../components/dashboard/AuraMap";
import AuraClimateAnalysis from "./AuraClimateAnalysis";
import AuraAnalysisInsights from "./AuraAnalysisInsights";

function NewAuraReport2() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [insights, setInsights] = useState("");
  const [insights3, setInsights3] = useState("");
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [weatherReports, setWeatherReports] = useState(null);
  const [status, setRiskCalculationStatus] = useState(null);
  const [coords, setCoords] = useState(null);
  const [culture, setCulture] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ticketData = await fetchTicketData(id);
        setRiskCalculationStatus(ticketData.risk_calculation_status);
        setCoords([ticketData.center_coords.lat, ticketData.center_coords.long]);
        setInsights(ticketData?.ai_insights?.ai_analysis);
        if (ticketData?.ai_insights?.ai_analysis) {
          const topics = ticketData.ai_insights.ai_analysis.split(/(?=\d+\.\s)/);
          setInsights3(topics[2]);
        }
        if (ticketData?.risk_classification_records.length >= 5) {
          const largestDateObject = ticketData?.risk_classification_records.filter(
            item => item.sowing_date.includes('2023') || item.sowing_date.includes('2024')).reduce(
              (max, item) => max.sowing_date > item.sowing_date ? max : item, { sowing_date: '' });

          setCulture(largestDateObject.culture);
        }
        setData(ticketData);
      } catch (error) {
        console.error("Error loading ticket data:", error);
      }

      try {
        const weatherData = await fetchWeatherReports(id);
        setWeatherReports(weatherData);
      } catch (error) {
        console.error("Error loading NDVI data:", error);
      }

      setIsLoading(false);
    };

    if (id) {
      if (data == null) {
        fetchData();
      } else if (status === "calculating" || status == null) {
        const timeout = setTimeout(() => {
          fetchData();
        }, 20000);

        return () => clearTimeout(timeout);
      }
    }
  }, [id, data]);

  useEffect(() => {
    setIsLoading(true);
  }, [id]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  const handleInsights = async () => {
    setLoadingInsights(true);
    const insightsReponse = await fetchAuraInsights(id);

    if (insightsReponse) {
      setInsights(insightsReponse?.ai_insight?.ai_analysis);
      if (insightsReponse?.ai_insight?.ai_analysis) {
        const topics = ticketData.ai_insights.ai_analysis.split(/\n\n/);
        setInsights3(topics[2]);
      }
    }
    setLoadingInsights(false);
  };

  const renderRiskAnalysisTab = () => (
    <div className="elementFlexVertical">
      <div className="elementFlexHorizontal">
        <div className="elementFlexVertical" style={{ width: "30%", minWidth: "500px" }}>
          <AuraInsights
            insights={insights}
            onGenerateInsight={handleInsights}
            loadingInsights={loadingInsights}
            crop={culture}
          />
          <AuraFieldAnalysis data={weatherReports} />
        </div>
        <AuraHistoricalParameters
          className="auraHistricalParams"
          data={data?.risk_classification_records}
          isCalculating={data?.risk_classification_records?.length < data?.total_classifications}
        />
      </div>
      <div className="elementFlexHorizontal">
        <AuraESGCards
          className="auraESGCard"
          data={data?.risk_classification_records}
          isLoading={data?.risk_score ? data?.risk_score : null}
          sicar_overlaps={data?.sicar_overlaps}
        />
        <ClimaticRisk className="AuraClimaticRisk" data={weatherReports} />
      </div>
      <RainfallChart
        className="AuraRainFall"
        data={weatherReports?.rainfall_data?.rainfall_sum ?? []}
      />
    </div>
  );

  const renderSoilAnalysisTab = () => (
    <div className="elementFlexHorizontal">
      <div className="elementFlexVertical" style={{ width: "23%" }}>
        <AuraClimateAnalysis
          data={weatherReports}
          crop={culture}
        />
        <AuraAnalysisInsights
          insights={insights3}
          onGenerateInsight={handleInsights}
          loadingInsights={loadingInsights}
          weatherReports={weatherReports}
        />
      </div>
      <div className="elementFlexVertical" style={{ width: "76%" }}>
        <AuraMap
          id={data?.id}
          coords={coords}
          graphData={weatherReports?.organic_matter_graph_data}
          ndviGraphData={weatherReports?.curve_insights}
          classificationRecords={data?.risk_classification_records}
        />
      </div>
    </div>
  );

  return (
    <>
      <AuraHeader ticket={data} />
      <Tabs defaultActiveKey="1" tabBarGutter={32} items={[
        {
          key: "1",
          label: <span className="auraTabText">Análise de risco - Safra Verão</span>,
          children: renderRiskAnalysisTab(),
        },
        {
          key: "2",
          label: <span className="auraTabText">Análise do solo</span>,
          children: renderSoilAnalysisTab(),
        }
      ]} />
    </>
  );
}

export default NewAuraReport2;
