import React from "react";
import { Spin, Carousel, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./LoadingPage.css";

const { Text } = Typography;

const LoadingPage = ({ customMessages }) => {
  const messages = customMessages || [
    "Gerando Relatório...",
    "Verificando critérios ESG...",
    "Validando dados históricos...",
    "Calculando produtividade...",
    "Coletando dados climáticos...",
  ];

  return (
    <div className="loadingPage">
      <div className="loadingPageCarouselContainer">
        <Carousel autoplay className="loadingPageCarousel" dots={false}>
          {messages.map((message, index) => (
            <div key={index}>
              <Text className="loadingPageMessage">{message}</Text>
            </div>
          ))}
        </Carousel>
      </div>
      <Spin indicator={<LoadingOutlined className="loadingPageSpin" spin />} />
    </div>
  );
};

LoadingPage.defaultProps = {
  customMessages: null,
};

export default LoadingPage;
