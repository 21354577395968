import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const AuthContext = createContext(null);

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://app.agromai.com.br/api/v1';

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = Cookies.get("auth-token");
    const email = Cookies.get("email");
    return token && email ? { email, token } : null;
  });

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/login/`,
        {
          email,
          password,
        }
      );

      const { token, user_id, role } = response.data;
      Cookies.set("auth-token", token, { expires: 1 }); // Expires in 1 day
      Cookies.set("email", email, { expires: 1 }); // Expires in 1 day
      Cookies.set("user-id", user_id, { expires: 1 }); // Expires in 1 day
      Cookies.set("role", role, { expires: 1 }); // Expires in 1 day
      setUser({ email, token, user_id, role });

      return true;
    } catch (error) {
      if (error.response && error.response.data === "Invalid Credentials") {
        console.error("Invalid credentials provided");
      } else {
        console.error("Login failed", error);
      }
      return false;
    }
  };

  const logout = () => {
    Cookies.remove("auth-token");
    Cookies.remove("email");
    Cookies.remove("user-id");
    Cookies.remove("role");
    setUser(null);
  };

  const isAuthenticated = () => {
    return !!user;
  };

  useEffect(() => {
    const handleCookieChange = () => {
      const token = Cookies.get("auth-token");
      const email = Cookies.get("email");
      const user_id = Cookies.get("user-id");
      if (!token || !email || !user_id) {
        setUser(null);
      } else {
        setUser({ email, token, user_id });
      }
    };

    window.addEventListener("storage", handleCookieChange);
    return () => window.removeEventListener("storage", handleCookieChange);
  }, []);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
