import { FrownFilled } from "@ant-design/icons";
import { Button, Popover, Result } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import HelpPopout from "../components/layout/HelpPopout";

const Unauthorized = () => {
  const [open, setOpen] = useState(false);

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);

    if (pathSegments.length > 1) {
      pathSegments.pop();
      const newPath = `/${pathSegments.join("/")}`;
      window.location.href = newPath;
    } else {
      window.location.href = "/";
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Result
      className="errorBoundary"
      icon={<FrownFilled style={{ color: "#FF4D4F" }} />}
      title="Você não tem permissão para visualizar essa página"
      subTitle="Fale com seu gerente ou entre em contato com o suporte para requisitar o acesso."
      extra={[
        <Button
          className="buttonCustomWhite"
          key={"newForm"}
          onClick={handleBack}
        >
          Voltar
        </Button>,
        <Popover
          content={<HelpPopout closePopover={() => setOpen(false)} />}
          trigger="click"
          placement="right"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <Button
            className="buttonCustom"
            key={"seeHistory"}
            onClick={() => setOpen(!open)}
          >
            Reportar um erro
          </Button>
        </Popover>,
      ]}
    />
  );
};

export default Unauthorized;
