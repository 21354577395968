
import React from "react";
import "./Aura.css";
import { Progress } from "antd";
import YardOutlinedIcon from '@mui/icons-material/YardOutlined';
import { AgricultureOutlined, PriceChangeOutlined, SafetyCheckOutlined } from "@mui/icons-material";

const AuraFieldAnalysis = ({ data }) => {
    const NoTillFarming = ({ steps }) => {
        const options = {
            1: {
                strokeColors: ["#52C41A", "#52C41A", "#52C41A"],
                progress: 99,
                text: "Sim"
            },
            2: {
                strokeColors: ["#FF4D4F"],
                progress: 33,
                text: "Não"
            },
            3: {
                strokeColors: ["#FAAD14", "#FAAD14"],
                progress: 66,
                text: "Indefinido"
            },
        }

        const selectedOption = options[steps] || options[3];

        return (
            <Progress
                percent={selectedOption.progress}
                steps={3}
                strokeColor={selectedOption.strokeColors}
                format={() => (selectedOption.text)}
            />
        );
    };

    return (
        <div className="auraFieldAnalysisContainer">
            <div className="auraFieldAnalysisCardTitle">
                Análise da gleba - 23/24
            </div>
            <div className="auraFieldAnalysisContent">
                <div className="auraFieldAnalysisColumn">
                    <div
                        className="auraFieldAnalysisSection"
                    >
                        <YardOutlinedIcon style={{ fontSize: 48, color: "#00000040" }} />
                        <div>
                            <div className="auraFieldAnalysisTitle">Plantio direto</div>
                            <NoTillFarming steps={data.no_till_planting_class}/>
                        </div>
                    </div>
                    <div
                        className="auraFieldAnalysisSection"
                    >
                        <SafetyCheckOutlined style={{ fontSize: 48, color: "#00000040" }} />
                        <div>
                            <div className="auraFieldAnalysisTitle">Garantia estimada - Gleba</div>
                            <div className="auraFieldAnalysisValue">{data?.land_pricing ? `R$ ${(data.land_warranty).toLocaleString('pt-BR')}` : "Indisponível"}</div>
                        </div>
                    </div>
                </div>
                <div className="auraFieldAnalysisColumn">
                    <div
                        className="auraFieldAnalysisSection"
                    >
                        <AgricultureOutlined style={{ fontSize: 48, color: "#00000040" }} />
                        <div>
                            <div className="auraFieldAnalysisTitle">Período de solo vazio</div>
                            <div className="auraFieldAnalysisValue">{`${data.days_with_bare_soil} dia${data.days_with_bare_soil !== 1 ? "s" : ""}`}</div>
                        </div>
                    </div>
                    <div
                        className="auraFieldAnalysisSection"
                    >
                        <PriceChangeOutlined style={{ fontSize: 48, color: "#00000040" }} />
                        <div>
                            <div className="auraFieldAnalysisTitle">Preço Médio Hectare</div>
                            <div className="auraFieldAnalysisValue">{data?.land_pricing ? `R$ ${(data.land_pricing).toLocaleString('pt-BR')}` : "Indisponível"} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuraFieldAnalysis;
