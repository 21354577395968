import React from "react";
import { fetchSenseTickets } from "../components/api/api";
import TicketListingSenseBasic from "../components/TicketListingSenseBasic";

function SenseBasic() {  
  return (
    <div>
      <TicketListingSenseBasic fetchTickets={fetchSenseTickets}/>
    </div>
  );
}

export default SenseBasic;
