import React from 'react';
import ReactApexChart from 'react-apexcharts';

import "./Billing.css"

const VisionChart = ({ data }) => {
    const areaData = data.daily_area.map(entry => entry.total_area);

    const options = {
        chart: {
            type: "area",
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        colors:['#6bcc3c'],
        fill: {
            
        
        colors: ['#b4e4a4','#6bcc3c', ],
        stroke: {
            width: 0,
            curve: "smooth"
        }},
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            min: 0,
            logarithmic: true,
            labels: {
                show: false
            }
        },
        grid: {
            show: false
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false
        }
    };
    
    const series = [{
        data: areaData
    }];

    return (
        <div className='billingChartCard'>
            <div className='billingChartTitle'>Vision - Laudo</div>
            <div className='billingChartNumber'>
                {Math.floor(data.area_total).toLocaleString('pt-BR')} Hec
            </div>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={80}
            />
        </div>
    );
}
export default VisionChart;