//getColor.js
function getColor(value, colorMap) {
  const pairs = colorMap.split(", ").map((item, index) => {
    if (index % 2 === 0) return parseFloat(item);
    return item.replace(/\"/g, "");
  });

  const thresholds = [];
  for (let i = 0; i < pairs.length; i += 2) {
    thresholds.push([pairs[i], pairs[i + 1]]);
  }

  for (let i = 1; i < thresholds.length; i++) {
    if (value <= thresholds[i][0]) {
      return thresholds[i][1];
    }
  }

  return thresholds[thresholds.length - 1][1];
}

export default getColor;
