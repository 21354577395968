import React from "react";
import { Button } from "antd";
import "./FilterButton.css";


function FilterButton({
  label,
  value,
  iconName,
  gradient,
  filteredBtn,
  setFilter,
  loading 
}) {
  return (
    <Button
      className={`filterButton ${filteredBtn === value ? "filtered" : ""} customMapButton`}
      onClick={() => setFilter(value)}
      style={{ backgroundImage: gradient }} 
      type="primary"
    >
      <div className="mapButtonLabel">{label}</div>
      <img src={`/icons/${iconName}`} alt="Organic Material" />
    </Button>
  );
}

export default FilterButton;
