import React, { useState, useEffect } from "react";
import { Card, Typography, Skeleton } from "antd";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");

const { Text } = Typography;

const WindSpeedChartTwoWeeks = ({ data }) => {
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (data) {
      updateChartData(data);
    }
  }, [data]);

  const updateChartData = (data) => {
    const dates = Object.keys(data);
    const series = [
      {
        name: "Velocidade do vento (Máx)",
        data: dates.map((date) => {
          const value = data[date].max;
          return value === 0 || value === "" ? null : value;
        }),
      },
    ];
    setChartSeries(series);
  };

  if (!data || Object.keys(data).length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;
  }

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: Object.keys(data).map((date) => dayjs(date).format("DD/MM")),
    },
    yaxis: {
      labels: {
        formatter: (val) => `${val} Km/h`,
      },
    },
    plotOptions: {
      line: {
        connectNullData: false,
      },
    },
    stroke: {
      colors: ["#1E90FF"],
      width: 2,
    },
    markers: {
      size: 5,
      colors: ["#1E90FF"],
    },
  };

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#B64CFF",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              maxWidth: "calc(100% - 100px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Gráficos de velocidade dos ventos
          </Text>
        </div>
      }
      bordered={true}
      style={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        flex: 1,
      }}
    >
      <ReactApexChart
        options={options}
        series={chartSeries}
        type="line"
        height={"320px"}
      />
    </Card>
  );
};

export default WindSpeedChartTwoWeeks;
