import React, { useState, useEffect } from "react";
import { fetchVisionDashboardData } from "../components/api/api";

import "./VisionDashboard.css";
import { Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import VisionDashboardMap from "../components/visionDashboard/VisionDashboardMap";
import { polygonStylePatterns } from "./polyStyles";

function VisionDashboard() {
  const [carData, setCarData] = useState(null);
  const [esgData, setEsgData] = useState(null);
  const [cropscanData, setCropscanData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFeature, setSelectedFeature] = useState("CAR");
  const [selectedOption, setSelectedOption] = useState("1");
  const [layerVisibility, setLayerVisibility] = useState({
    CAR: true,
    ESG: true,
    CROPSCAN: true,
  });

  const municipalityOptions = [
    { value: "1", label: "Tupanciretã, RS" },
    { value: "2", label: "Camaquã, RS" },
    { value: "3", label: "Toledo, PR" },
  ];

  const municipalityInfo = {
    1: {
      state: "RS",
      cropscancoord: [-28.966405862140316, -54.22351556290216],
      esgcoord: [-28.094539435938366, -53.99791229725585]

    },
    2: {
      state: "RS",
      cropscancoord: [-30.983415456725428, -51.64598579343145],
      esgcoord: [-31.064193724858875, -50.8987255432914]
    },
    3: {
      state: "PR",
      cropscancoord: [-24.665828009244517, -53.84340717919074],
      esgcoord: [-24.667287766983307, -52.913106596741414]
    },
  }

  const layer_list = [
    "CAR",
    "ESG",
    "CROPSCAN"
  ];

  const prettierLegendDict = {
    name: {
      CAR: "CARs",
      ESG: "ESG",
      CROPSCAN: "Cropscan",
    },
    color: {
      CAR: "#1890FF",
      ESG: "#52C41A",
      CROPSCAN: "#E5B636",
    }
  };

  const prettierCultureLegendDict = {
    204: {
      label: "Soja",
      color: polygonStylePatterns.sojaArea.fillColor,
    },
    3: {
      label: "Milho",
      color: polygonStylePatterns.milhoArea.fillColor,
    },
    4: {
      label: "Outro",
      color: polygonStylePatterns.outroArea.fillColor,
    },
  };

  const toggleLayer = (layer) => {
    setLayerVisibility((prevState) => ({
      ...prevState,
      [layer]: !prevState[layer],
    }));
  };

  const getUniquePredictedCrops = (cropscanData) => {
    if (!cropscanData) return [];

    const predictedValues = cropscanData.features.map(
      (feature) => feature.properties.predicted
    );

    return Array.from(new Set(predictedValues));
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);

      const { state, cropscancoord, esgcoord } = municipalityInfo[selectedOption];
      const [clat, clon] = cropscancoord;
      const [elat, elon] = esgcoord;

      try {
        const [carResult, esgResult, cropscanResult] = await Promise.all([
          fetchVisionDashboardData(state, elon, elat, "CAR"),
          fetchVisionDashboardData(state, elon, elat, "ESG"),
          fetchVisionDashboardData(state, clon, clat, "CROPSCAN"),
        ]);

        setCarData(carResult.result.CAR);
        setEsgData(esgResult.result.ESG);
        setCropscanData(cropscanResult.result.CROPSCAN);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [selectedOption]);

  const uniqueCrops = getUniquePredictedCrops(cropscanData);

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <div className="visionDashboardFilterContainer">
        <div className="visionDashboardFilterCard">
          <div className="visionDashboardFilterTitle">
            Filtrar dados
            {isLoading && <Spin
              indicator={<LoadingOutlined
                style={{
                  fontSize: 20,
                  color: "#ffffffd9",
                }}
                spin
              />}
            />}
          </div>
          <Select
            className="visionDashboardStateSelect"
            placeholder="Estado"
            value={selectedOption}
            onChange={(value) => setSelectedOption(value)}
            options={municipalityOptions}
          />
        </div>
        <div className="visionDashboardFilterCard">
          <div className="visionDashboardFilterTitle">Legenda</div>
          <div className="visionDashboardLegendContent">
            {layer_list.map((id, index) => (
              <div
                key={index}
                className="visionMapLegendLine"
                onClick={() => toggleLayer(id)}
              >
                <div
                  className="visionMapLegendSquare"
                  style={{
                    backgroundColor: prettierLegendDict.color[id],
                    opacity: layerVisibility[id] ? 1 : 0.35,
                    cursor: "pointer",
                  }}
                ></div>
                <div className="visionDashboardLegendText">
                  {prettierLegendDict.name[id]}
                </div>
              </div>
            ))}
          </div>
        </div>
        {layerVisibility.CROPSCAN && uniqueCrops.length > 0 && <div className="visionDashboardFilterCard">
          <div className="visionDashboardFilterTitle">Legenda Cropscan</div>
          <div className="visionDashboardLegendContent">
            {uniqueCrops.map((crop, index) => (
              <div
                key={index}
                className="visionMapLegendLine"
              >
                <div
                  className="visionMapLegendSquare"
                  style={{
                    backgroundColor: prettierCultureLegendDict[crop].color
                  }}
                />
                <div className="visionDashboardLegendText">
                  {prettierCultureLegendDict[crop].label}
                </div>
              </div>
            ))}
          </div>
        </div>}
      </div>
      <VisionDashboardMap
        key={JSON.stringify(carData)}
        carData={carData}
        esgData={esgData}
        cropscanData={cropscanData}
        layerVisibility={layerVisibility}
      />
    </div>
  );
}

export default VisionDashboard;
