import React, { useState } from "react";
import atypicalEventsContent from "./AtypicalEventsContent";
import { Steps, Typography, Divider, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./AtypicalEvents.css";
import dayjs from 'dayjs';

const { Text } = Typography;
const { Step } = Steps;

function getLastDay(notificationType, startDate) {
  let daysToAdd = 0;

  switch (notificationType) {
    case "THREE_DAY_HIGH":
    case "THREE_DAY_VERY_HIGH":
      daysToAdd = 2;
      break;
    case "FIVE_DAY_HIGH":
    case "FIVE_DAY_VERY_HIGH":
      daysToAdd = 4;
      break;
    case "DAILY_HIGH":
    case "DAILY_VERY_HIGH":
    default:
      daysToAdd = 0;
  }

  return dayjs(startDate).add(daysToAdd, 'day');
}

const AtypicalEvents = ({ notifications = [], esg_detections = [] }) => {
  const [current, setCurrent] = useState(0);

  const esgNotifications = Array.isArray(esg_detections)
    ? esg_detections.map((detection) => ({
        notificationType: `id_esg_${detection.id_esg}`,
      }))
    : [];

    const climateNotifications = Array.isArray(notifications)
    ? notifications.map((detection) => ({
        notificationType: detection.notification_type,
        dateStart: dayjs(detection.notification_date),
        dateEnd: getLastDay(detection.notification_type, detection.notification_date),
      }))
    : [];
  
  climateNotifications.sort((a, b) => b.dateEnd.diff(a.dateEnd));
  
  const formattedClimateNotifications = climateNotifications.map((notification) => ({
    ...notification,
    dateStart: notification.dateStart.format("DD/MM/YYYY"),
    dateEnd: notification.dateEnd.format("DD/MM/YYYY"),
  }));

  const combinedNotifications = [...esgNotifications, ...formattedClimateNotifications];

  const validNotifications = combinedNotifications.filter(
    (notification) =>
      notification.notificationType in atypicalEventsContent
  );

  const notificationsToShow =
    validNotifications.length > 0 ?
      validNotifications :
      [{ notificationType: "default" }];

  const next = () => {
    setCurrent((prev) => Math.min(prev + 1, notificationsToShow.length - 1));
  };

  const prev = () => {
    setCurrent((prev) => Math.max(prev - 1, 0));
  };

  const content =
    atypicalEventsContent[notificationsToShow[current].notificationType] ||
    atypicalEventsContent["default"];

  return (
    <div className="atypicalEventsCard">
      <Text
        className="atypicalEventsTitle"
        style={{ color: content.titleColor }}
      >
        Eventos Atípicos
      </Text>
      <Divider className="dividerCustom" />
      <div className="atypicalEventsContent">
        <div>
          <div
            className="atypicalEventsFlex"
            style={{
              display: "flex",
              flexDirection: content.specialStyling,
              alignItems: "center",
              gap: "12px",
              marginTop: content?.specialMargingTop || "",
            }}
          >
            {content.icon && (
              <img
                src={content.icon}
                alt={content.title}
                className="atypicalEventsIcon"
              />
            )}
            <div>
              <Text className="atypicalEventsDetail">{content.title}</Text>
              <br />
              {notificationsToShow[current]?.dateStart && notificationsToShow[current]?.dateEnd && (
                <Text className="atypicalEventsDate">
                  {`${notificationsToShow[current]?.dateStart} - ${notificationsToShow[current]?.dateEnd}`}
                </Text>
              )}
            </div>
          </div>
        </div>
        <Text 
          className={content.recommendation === "" ?
                     "noAtypicalEventsDescription" :
                     "atypicalEventsDescription"}
        >
          {content.description}
        </Text>
        {content.recommendation && (
          <Text className="atypicalEventsDescription">
            {content.recommendation}
          </Text>
        )}
      </div>
      <div
        className="stepNavigation"
      >
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={prev}
          disabled={current === 0}
        />
        <Steps
          current={current}
          direction="horizontal"
          size="small"
          style={{ background: "none" }}
        >
          {notificationsToShow.map((_, index) => (
            <Step key={index} icon={<div className="custom-step-circle" />} />
          ))}
        </Steps>
        <Button
          type="link"
          icon={<RightOutlined />}
          onClick={next}
          disabled={current === notificationsToShow.length - 1}
        />
      </div>
    </div>
  );
};

export default AtypicalEvents;
