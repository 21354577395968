function generateTemperatureData(monthlyWeatherData) {
    let temperatureData = [];
  
    if (monthlyWeatherData && monthlyWeatherData.temp_avg && monthlyWeatherData.temp_min && monthlyWeatherData.temp_max) {
      temperatureData = monthlyWeatherData.temp_avg.map((yearData, index) => {
        const dataForYear = yearData.data.map((monthData, monthIndex) => {
          return {
            date: monthData.date,
            temp_min: monthlyWeatherData.temp_min[index]?.data[monthIndex]?.value || 0,
            temp_avg: monthlyWeatherData.temp_avg[index]?.data[monthIndex]?.value || 0,
            temp_max: monthlyWeatherData.temp_max[index]?.data[monthIndex]?.value || 0,
          };
        });
  
        return {
          year: yearData.year,
          data: dataForYear,
        };
      });
    }
    return temperatureData;
  }
  
  export default generateTemperatureData;
  