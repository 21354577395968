import {
    Row,
    Col,
    Tabs,
    Flex,
    theme,
    Steps,
    Radio,
    Modal,
    Button,
    Divider,
    Typography, Segmented, Spin,
} from "antd";
import {
    LeftOutlined,
    CloudOutlined,
    RightOutlined,
    PrinterOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MenuFoldOutlined,
    DownloadOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import {useParams} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {Content} from "antd/es/layout/layout";
import "./SenseBasic.css";
import {polygonStylePatterns} from "./polyStyles";
import React, {useEffect, useState, useRef} from "react";
import "../components/dashboard/PlantingWindow.css";
import getStatus from "../components/dashboard/Status";
import LoadingPage from "../components/dashboard/LoadingPage";
import {GeoJSON, ImageOverlay, MapContainer, TileLayer} from "react-leaflet";
import ESGCardsList from "../components/dashboard/ESGCardsList";
import Notifications from "../components/dashboard/Notifications";
import AtypicalEvents from "../components/dashboard/AtypicalEvents";
import PlantingWindow from "../components/dashboard/PlantingWindow";
import formatDate from "../components/dashboard/functions/formatDate";
import generateAlerts from "../components/dashboard/functions/GenerateAlerts";
import PrecipitationChart from "../components/dashboard/PrecipitationChart";
import {fetchSenseDatedReports, fetchTicketData, fetchTrueImageDownload} from "../components/api/api";

const {TabPane} = Tabs;
const {Title, Text} = Typography;

function SenseBasicReport() {
    const {id} = useParams();
    const {token} = theme.useToken();
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [coords, setCoords] = useState(null);
    const [retry, setRetry] = useState(0);
    const [whData, setWhData] = useState([]);
    const [current, setCurrent] = useState(0);
    const [ndviData, setNdviData] = useState([]);
    const [reportId, setReportId] = useState("");
    const [realAlerts, setRealAlerts] = useState([]);
    const [reportDate, setReportDate] = useState("");
    const [originData, setOriginData] = useState([]);
    const [selectedChart, setSelectedChart] = useState("growthCurve");
    const [avgProductivity, setAvgProductivity] = useState([]);
    const [highProductivity, setHighProductivity] = useState([]);
    const [lowProductivity, setLowProductivity] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportDates, setReportDates] = useState([]);
    const [minTempData, setMinTempData] = useState("");
    const [avgTempData, setAvgTempData] = useState("");
    const [maxTempData, setMaxTempData] = useState("");
    const [productivityDates, setProductivityDates] = useState([]);
    const [kmlGeometry, setKmlGeometry] = useState("");
    const [selectedReport, setSelectedReport] = useState(null);
    const [maxWindSpeed, setMaxWindSpeed] = useState("");
    const [senseParamsData, setSenseParamsData] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");
    const [trueImageData, setTrueImageData] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState("a");
    const [geometryCoords, setGeometryCoords] = useState([]);
    const [areaPercentage, setAreaPercentage] = useState("");
    const [precipitationData, setPrecipitationData] = useState([]);
    const [precipitationDate, setPrecipitationDate] = useState([]);
    const [totalPrecipitation, setTotalPrecipitation] = useState(0);
    const [zarcCompliance, setZarcCompliance] = useState([])
    const [estimatedSowingDate, setEstimatedSowingDate] = useState("");
    const [estimatedHarvestDate, setEstimatedHarvestDate] = useState("");
    const [chartData, setChartData] = useState({
        series: [],
        options: {},
    });
    const [showGeometries, setShowGeometries] = useState({0: true, 1: true})
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ticketData, senseParams] = await Promise.all([
                    fetchTicketData(id),
                    fetchSenseDatedReports(id)
                ]);
                setData(ticketData);
                setIsLoading(false);
                setSenseParamsData(senseParams);
                setStatus(getStatus(ticketData));
                setFormattedDate(formatDate(ticketData.created_at));
                if (senseParams.results && senseParams.results.length > 0) {
                    // Extract report dates
                    const datesArray = senseParams.results.map(item => ({
                        label: new Date(item.report_date).toLocaleDateString('en-GB'),
                        key: item.id,
                        data: item,
                    }));
                    setReportDates(datesArray);
                    setSelectedReport(datesArray[0].data);
                    updateReportData(datesArray[0].data);
                    // Loop through results
                    senseParams.results.forEach(result => {
                        const precipitation = result?.weather_info?.precipitation || [];
                        setPrecipitationData(precipitation);
                        // Calculate total precipitation
                        const total = calculateTotal(precipitation);
                        setTotalPrecipitation(total);
                        // Extract harvest monitoring data (coordinates)
                        const harvestData = result?.harvest_monitoring_data;
                        const validFeatures = harvestData?.features?.map((feature) => ({
                            type: "Feature",
                            ...feature,
                        })) || [];
                        setGeometryCoords({
                            type: "FeatureCollection",
                            features: validFeatures,
                        });

                        // Update state variables
                        setKmlGeometry(data?.farm_data?.kml_document?.kml_polygon?.geometry || "");
                        setProductivityDates(result?.reference_graph_data.point_dates || []);
                        setAvgProductivity(result?.reference_graph_data.avg_productivity || []);
                        setHighProductivity(result?.reference_graph_data.high_productivity || []);
                        setLowProductivity(result?.reference_graph_data.low_productivity || []);
                        setEstimatedSowingDate(result?.plant_health.estimated_sowing_date || "");
                        setEstimatedHarvestDate(result?.plant_health.estimated_harvest_date || "");
                        setAreaPercentage(result?.area_usage?.total_sowed_area?.percentage || "");
                        setCoords([ticketData.center_coords?.lat || 0, ticketData.center_coords?.long || 0]);
                        setReportDate(result.report_date || "");
                        setReportId(result.id || "");
                        setTrueImageData(result?.true_image_data?.image_url || "");
                        setWhData([result?.true_image_data?.bbox[0][1], result?.true_image_data?.bbox[0][0]] || [])
                        setOriginData([result?.true_image_data?.bbox[1][1], result?.true_image_data?.bbox[1][0]] || [])
                        setNdviData(result.plant_health?.plant_health_graph_data || []);
                        setMaxWindSpeed(result.weather_info?.parameters_info?.max_windspeed || "");
                        setAvgTempData(result.weather_info?.parameters_info?.avg_temp || "");
                        setMaxTempData(result.weather_info?.parameters_info?.max_temp || "");
                        setMinTempData(result.weather_info?.parameters_info?.min_temp || "");
                        setPrecipitationDate(result.weather_info.dates || []);
                        setZarcCompliance(report?.zarc_compliance || []);
                        setRealAlerts(generateAlerts(result.notifications, ticketData.sicar_overlaps));
                    });
                }
            } catch (error) {
                setRetry(prevRetry => prevRetry + 1);
                setStatus(error.response?.status === 404 ? 'error, not found in database' : 'error');
                setIsLoading(false);
            }
        };
        if (data == null && retry === 0) {
            fetchData();
        } else if (status !== 'success') {
            const timeout = setTimeout(fetchData, 1000);
            return () => clearTimeout(timeout);
        }
    }, [id, status]);

    // Handle updating report data based on selected report
    const updateReportData = (report, ticketData) => {
        setSelectedReport(report);
        // Precipitation data and total calculation
        const precipitation = report?.weather_info?.precipitation || [];
        setPrecipitationData(precipitation);
        // Calculate total precipitation and update the state
        const total = calculateTotal(precipitation);
        setTotalPrecipitation(total);
        setCoords([ticketData?.center_coords?.lat || 0, ticketData?.center_coords?.long || 0]);
        // Set other state variables
        setKmlGeometry(data?.farm_data?.kml_document?.kml_polygon?.geometry || "");
        setProductivityDates(report?.reference_graph_data.point_dates || []);
        setAvgProductivity(report?.reference_graph_data.avg_productivity || []);
        setHighProductivity(report?.reference_graph_data.high_productivity || []);
        setLowProductivity(report?.reference_graph_data.low_productivity || []);
        setAreaPercentage(report?.area_usage?.total_sowed_area?.percentage || 0);
        setNdviData(report?.plant_health?.plant_health_graph_data || []);
        setMaxWindSpeed(report?.weather_info?.parameters_info?.max_windspeed || "");
        setAvgTempData(report?.weather_info?.parameters_info?.avg_temp || "");
        setMaxTempData(report?.weather_info?.parameters_info?.max_temp || "");
        setReportId(report?.id || "");
        setMinTempData(report?.weather_info?.parameters_info?.min_temp || "");
        setTrueImageData(report?.true_image_data?.image_url || "");
        setWhData(report?.true_image_data?.bbox[0] || [])
        setOriginData(report?.true_image_data?.bbox[1] || [])
        setPrecipitationDate(report?.weather_info?.dates || []);
        setZarcCompliance(report?.zarc_compliance || []);
        setEstimatedSowingDate(report?.plant_health.estimated_sowing_date || "");
        setEstimatedHarvestDate(report?.plant_health.estimated_harvest_date || "");
        setRealAlerts(generateAlerts(report?.notifications, ticketData?.sicar_overlaps || []));
    };

    const harvestMonitorintStyle = (feature) => {
        const featureValue = String(feature.properties.value);
        if (!showGeometries[featureValue]) {
            return {
                fillOpacity: 0,
                opacity: 0,
                weight: 0,
            };
        }

        // Style the features based on their value
        switch (featureValue) {
            case '0':
                return {
                    fillColor: 'orange',
                    color: 'orange',
                    weight: 2,
                    fillOpacity: 0.7,
                };
            case '1':
                return {
                    fillColor: 'green',
                    color: 'green',
                    weight: 2,
                    fillOpacity: 0.7,
                };
            default:
                return polygonStylePatterns.kmlArea;
        }
    };
    console.log("Dados do Sense Params", senseParamsData);
    const handleReportDownload = async () => {
        if (!reportId) {
            console.error("Report ID is not available");
            return;
        }
        try {
            const {blob, headers} = await fetchTrueImageDownload(reportId);
            const contentDisposition = headers.get('content-disposition');
            let fileName = 'download.tiff';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };
    useEffect(() => {
        if (!ndviData || ndviData.length === 0) {
            return;
        }
        // **Process NDVI Data**
        let processedNdviData = ndviData
            .filter(item => item && item.date && !isNaN(new Date(item.date)))
            .map(item => ({
                ...item,
                dateObj: new Date(item.date),
            }))
            .filter(item => item.avg_value !== 0);
        processedNdviData.sort((a, b) => a.dateObj - b.dateObj);

        // Filter NDVI data
        const ndviFilteredData = [];
        const ndviTimeThreshold = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
        let ndviPreviousDate = null;

        for (let i = processedNdviData.length - 1; i >= 0; i--) {
            const currentDataPoint = processedNdviData[i];
            const currentDate = currentDataPoint.dateObj;

            if (
                ndviPreviousDate === null ||
                ndviPreviousDate - currentDate >= ndviTimeThreshold
            ) {
                ndviFilteredData.push(currentDataPoint);
                ndviPreviousDate = currentDate;
            }
        }
        ndviFilteredData.reverse();

        const ndviValuesArray = ndviFilteredData.map(item => item.avg_value);
        const ndviDatesArray = ndviFilteredData.map(item => item.dateObj.getTime());

        // **Prepare Report Date Chart**
        let reportDateChart = null;
        if (reportDate) {
            const reportDateObj = new Date(reportDate);
            if (!isNaN(reportDateObj.getTime())) {
                reportDateObj.setDate(reportDateObj.getDate() - 15);
                reportDateChart = reportDateObj.toISOString().split('T')[0];
            } else {
                console.error('Invalid reportDate:', reportDate);
            }
        } else {
            console.error('reportDate is null or undefined');
        }

        if (selectedChart === 'growthCurve') {
            // **Prepare Annotations**
            const annotations = [];

            // Estimated Sowing Date Annotation
            if (estimatedSowingDate) {
                annotations.push({
                    x: new Date(estimatedSowingDate).getTime(),
                    borderColor: "#12c2c1",
                    label: {
                        borderColor: "#12c2c1",
                        style: {
                            fontFamily: 'Outfit',
                            fontSize: '12px',
                            color: "#ffffff",
                            background: "#12c2c1",
                        },
                        text: "Data de Plantio",
                    },
                });
            }

            // Estimated Harvest Date Annotation
            if (estimatedHarvestDate) {
                annotations.push({
                    x: new Date(estimatedHarvestDate).getTime(),
                    borderColor: "#fa8b16",
                    label: {
                        borderColor: "#fa8b16",
                        style: {
                            fontFamily: 'Outfit',
                            fontSize: '12px',
                            color: "#ffffff",
                            background: "#fa8b16",
                        },
                        text: "Data de Colheita",
                    },
                });
            }

            // Report Date Chart Annotation
            if (reportDateChart) {
                annotations.push({
                    x: new Date(reportDateChart).getTime(),
                    borderColor: "#1791ff",
                    label: {
                        borderColor: "#1791ff",
                        style: {
                            fontFamily: 'Outfit',
                            fontSize: '12px',
                            color: "#ffffff",
                            background: "#1791ff",
                        },
                        text: "Início do Monitoramento",
                    },
                });
            }

            // **Update Chart Data for Growth Curve**
            setChartData({
                series: [
                    {
                        name: 'NDVI',
                        data: ndviValuesArray,
                    },
                ],
                options: {
                    stroke: {
                        width: 4,
                        dashArray: 0,
                    },
                    chart: {
                        id: 'avg-ndvi-chart',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '70%',
                        },
                    },
                    title: {
                        text: "Curva de crescimento",
                        align: "left",
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: ndviDatesArray,
                        labels: {
                            show: true,
                            datetimeUTC: false,
                            formatter: function (value) {
                                const date = new Date(value);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            },
                        },
                    },
                    yaxis: {
                        min: 0.0,
                        max: 1.0,
                        tickAmount: 8,
                        labels: {
                            formatter: (value) => value.toFixed(1),
                        },
                    },
                    grid: {
                        borderColor: '#90A4AE',
                        strokeDashArray: 3,
                    },
                    tooltip: {
                        x: {
                            formatter: function (value, {dataPointIndex}) {
                                const timestamp = ndviDatesArray[dataPointIndex];
                                const date = new Date(timestamp);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            },
                        },
                        y: {
                            formatter: function (val) {
                                return `${val}`;
                            },
                        },
                    },
                    colors: ['#6bcd3d'],
                    annotations: {
                        xaxis: annotations,
                    },
                },
            });
        } else if (selectedChart === 'productivityCurve') {
            // **Align Productivity Data with NDVI Dates**
            // Determine the number of data points to use
            const length = ndviValuesArray.length;
            if (
                highProductivity.length >= length &&
                avgProductivity.length >= length &&
                lowProductivity.length >= length
            ) {
                // Slice the productivity arrays to match the length of ndviValuesArray
                const highProductivityArray = highProductivity.slice(0, length);
                const avgProductivityArray = avgProductivity.slice(0, length);
                const lowProductivityArray = lowProductivity.slice(0, length);

                // **Update Chart Data**
                setChartData({
                    series: [
                        {
                            name: 'NDVI',
                            data: ndviValuesArray,
                        },
                        {
                            name: '60+ Sa/Ha',
                            data: highProductivityArray,
                        },
                        {
                            name: '35 Sa/Ha',
                            data: avgProductivityArray,
                        },
                        {
                            name: '15 Sa/Ha',
                            data: lowProductivityArray,
                        },
                    ],
                    options: {
                        chart: {
                            id: 'productivity-chart',
                            toolbar: {
                                show: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: '70%',
                            },
                        },
                        title: {
                            text: "Estimativa de produtividade",
                            align: "left",
                        },
                        colors: ['#6bcd3d', '#b2e2ff', '#ffd577', '#ff8184'],
                        stroke: {
                            width: [4, 4, 4, 4],
                            dashArray: [0, 6, 6, 6],
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            type: 'datetime',
                            categories: ndviDatesArray,
                            labels: {
                                show: false,
                                datetimeUTC: false,
                                formatter: function (value) {

                                },
                            },
                        },
                        yaxis: {
                            min: 0.0,
                            max: 1.0,
                            tickAmount: 8,
                            labels: {
                                formatter: (value) => value.toFixed(1),
                            },
                        },
                        grid: {
                            borderColor: '#90A4AE',
                            strokeDashArray: 3,
                        },
                        tooltip: {
                            x: {
                                formatter: function (value) {
                                    const date = new Date(value);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                },
                            },
                            y: {
                                formatter: function (val) {
                                    return val !== null ? `${val.toFixed(2)}` : 'N/D';
                                },
                            },
                        },
                    },
                });
            } else {
                console.error('Not enough productivity data to match NDVI data length.');
            }
        }
    }, [ndviData, selectedChart, highProductivity, avgProductivity, lowProductivity, reportDate]);

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
    };

    const handleSegmentedChange = (value) => {
        setSelectedChart(value);
    };

    const calculateTotal = (dataArray) => {
        if (!dataArray || dataArray.length === 0) return 0;
        return dataArray.reduce((acc, value) => acc + value, 0);
    };

    function convertToGeoJSON(polygonString) {
        // Remove the SRID and POLYGON part
        const coordinatesString = polygonString.replace('SRID=4326;POLYGON ((', '').replace('))', '');
        // Split the coordinates string into an array of coordinate pairs
        const coordinatesArray = coordinatesString.split(', ').map(coord => {
            const [lng, lat] = coord.split(' ').map(Number);
            return [lng, lat];
        });
        // Create the GeoJSON object
        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [coordinatesArray]
            },
            properties: {}
        };
    }

    if (isLoading) {
        return <LoadingPage/>;
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (value) => {
        setCurrent(value);
        const selected = reportDates[value]?.data;
        if (selected) {
            updateReportData(selected);
        }
    };

    const handleStepChange = (value) => {
        setCurrent(value);
        const selected = reportDates[value]?.data;
        if (selected) {
            updateReportData(selected);
        }
    };

    const handlePreviousStep = () => {
        if (current > 0) handleStepChange(current - 1);
    };

    const handleNextStep = () => {
        if (current < reportDates.length - 1) handleStepChange(current + 1);
    };

    const handleFirstStep = () => handleStepChange(0);

    const handleLastStep = () => handleStepChange(reportDates.length - 1);

    const handlePrint = () => {
        window.print();
    };

    return (
        <div>
            <div
                style={{
                    width: '100%',
                    display: "inline-flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                {data ? (
                    <Row gutter={24} style={{
                        alignItems: "center",
                    }}>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitleSenseBasic">ID do Cliente:</p>
                                <p className="farmInfoText ellipsisSenseBasic">
                                    {data?.farm_data?.farmer?.farmer_name ? data.farm_data.farmer.farmer_name : ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Fazenda:</p>
                                <p className="farmInfoText">{data?.farm_data?.farm_name || ""}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Talhão:</p>
                                <p className="farmInfoText">
                                    {data?.farm_data?.field_name || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">ID da Proposta:</p>
                                <p className="farmInfoText">{data?.farm_data?.proposal_id || ""}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Local:</p>
                                <p className="farmInfoText">
                                    {`${data?.farm_data?.kml_document?.kml_polygon?.municipality}, ${data?.farm_data?.kml_document?.kml_polygon?.state}`}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Cultura:</p>
                                <p className="farmInfoText">{data.farm_data?.crop_type}</p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Status:</p>
                                <p className="farmInfoText" style={{color: "#1890FF"}}>
                                    Sense
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Usuário:</p>
                                <p className="farmInfoText ellipsisSenseBasic" style={{color: "#B64CFF"}}>
                                    {data.subwriter || ""}
                                </p>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={2.5}>
                            <div className="item">
                                <p className="farmInfoTextTitle">Gerado em:</p>
                                <p className="farmInfoText">{formattedDate}</p>
                            </div>
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",  // Alinha o botão à direita dentro da coluna
                                flex: 1,                     // Garante que o Col se expanda proporcionalmente, sem exceder o necessário
                            }}
                        >
                            <Button
                                className="pdfButtonSenseBasic"
                                type="primary"
                                icon={<PrinterOutlined/>}
                                onClick={handlePrint}
                                style={{
                                    marginRight: "20px",  // Adiciona espaçamento entre o botão e a borda direita
                                }}
                            >
                                Gerar PDF
                            </Button>
                        </Col>
                    </Row>) : null}
            </div>
            <Divider/>
            <div
                style={{
                    height: "100%",
                    position: "auto",
                    overflow: "hidden"
                }}
            >
                <Row>
                    <Col span={17}>
                        <div
                            style={{
                                height: "650px",
                                padding: "10px",
                                position: "relative",
                                width: "100%",
                                borderRadius: token.borderRadiusLG,
                                background: token.colorFillAlter,
                                border: `1px solid ${token.colorBorderSecondary}`,
                            }}
                        >
                            <div className="senseBasicLegendCard">
                                <div className="senseBasicButtons">
                                    <Flex vertical gap="middle">
                                        <Radio.Group defaultValue="a" buttonStyle="solid" onChange={handleRadioChange}>
                                            <Radio.Button disabled={!trueImageData} value="b">{
                                                trueImageData ? "Imagem True Color" : "Gerando True Color"
                                            }</Radio.Button>
                                            <Radio.Button value="a">Área Plantada</Radio.Button>
                                        </Radio.Group>
                                    </Flex>
                                </div>
                                {selectedRadio !== 'b' && geometryCoords && (
                                    <div className="senseBasicLegends">
                                        <div className="senseBasicLegendContent">
                                            <div className="senseBasicLegendTitle">
                                                Legendas
                                            </div>
                                            <div
                                                className="senseBasicMapLegendLine"
                                                onClick={() => setShowGeometries(prevState => ({
                                                    ...prevState,
                                                    [1]: !prevState[1]
                                                }))}
                                            >
                                                <div
                                                    className="senseBasicMapLegendSquare"
                                                    style={{
                                                        backgroundColor: "#52C41A",
                                                        opacity: showGeometries[1] ? 1 : 0.5,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                                <div className="senseBasicLegendText">Área plantada</div>
                                            </div>
                                            <div
                                                className="senseBasicMapLegendLine"
                                                onClick={() => setShowGeometries(prevState => ({
                                                    ...prevState,
                                                    [0]: !prevState[0]
                                                }))}
                                            >
                                                <div
                                                    className="senseBasicMapLegendSquare"
                                                    style={{
                                                        backgroundColor: "#FF9709FF",
                                                        opacity: showGeometries[0] ? 1 : 0.5,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                                <div className="senseBasicLegendText">Área não plantada</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="senseBasicDownloadReportContainer">
                                    <Button
                                        className="senseBasicDownloadReportButton"
                                        type="primary"
                                        icon={<DownloadOutlined/>}
                                        onClick={handleReportDownload}
                                    >
                                        Baixar imagem
                                    </Button>
                                </div>
                            </div>
                            <div className="senseBasicReportSelectorCard">
                                <DoubleLeftOutlined
                                    className="DoubleLeftOutlined"
                                    onClick={handleFirstStep}
                                    disabled={current === 0}
                                />
                                <LeftOutlined
                                    className="LeftOutlined"
                                    onClick={handlePreviousStep}
                                    disabled={current === 0}
                                />
                                <div className="datePickerSpacer"></div>
                                <Steps
                                    className="custom-steps"
                                    type="inline"
                                    current={current}
                                    onChange={onChange}
                                    items={reportDates.map((item) => ({
                                        title: item.label,
                                        key: item.key,
                                    }))}
                                />
                                <div className="datePickerSpacer"></div>
                                <RightOutlined
                                    className="RightOutlined"
                                    onClick={handleNextStep}
                                    disabled={current === reportDates.length - 1}
                                />
                                <DoubleRightOutlined
                                    className="DoubleRightOutlined"
                                    onClick={handleLastStep}
                                    disabled={current === reportDates.length - 1}
                                />
                            </div>
                            {geometryCoords && kmlGeometry ? (
                                <MapContainer
                                    center={
                                        data?.center_coords && data?.center_coords?.lat && data?.center_coords?.long
                                            ? [data.center_coords.lat, data.center_coords.long]
                                            : [whData[1], whData[0]]
                                    }
                                    zoom={data?.center_coords ? 15 : 5}
                                    maxZoom={16}
                                    zoomControl={false}
                                    dragging={false}
                                    scrollWheelZoom={false}
                                    doubleClickZoom={false}
                                    touchZoom={false}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <TileLayer
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                    />
                                    <TileLayer
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                    />

                                    {trueImageData && selectedRadio === 'b' && (
                                        <ImageOverlay
                                            url={trueImageData} // URL for the overlay
                                            bounds={whData && originData ? [whData, originData] : undefined} // Use bounds if available
                                        />
                                    )}

                                    {geometryCoords && selectedRadio === 'a' && (
                                        <GeoJSON data={geometryCoords} style={harvestMonitorintStyle}/>
                                    )}
                                    <GeoJSON
                                        data={convertToGeoJSON(kmlGeometry)}
                                        style={polygonStylePatterns["fieldArea"]}
                                    />
                                </MapContainer>
                            ) : (
                                <div style={{textAlign: "center", padding: "200px 200px"}}>
                                    <div style={{textAlign: 'center', padding: '20px'}}>
                                        Carregando o mapa, por favor aguarde...
                                    </div>
                                    <br/>
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{fontSize: 68, color: "#B64CFF"}}
                                                spin
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <Content>
                            <div
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <div className="evolutionChartContainer">
                                            <Row justify="space-between">
                                                <div className="evolutionChartTitle">Gráfico de desenvolvimento</div>
                                                <div className="evolutionChartButtons">
                                                    <Flex vertical gap="end">
                                                        <Segmented
                                                            options={[
                                                                {label: 'Curva de crescimento', value: 'growthCurve'},
                                                                {
                                                                    label: 'Estimativa de produtividade',
                                                                    value: 'productivityCurve'
                                                                },
                                                            ]}
                                                            defaultValue="growthCurve"
                                                            onChange={handleSegmentedChange}
                                                        />
                                                    </Flex>
                                                </div>
                                            </Row>
                                            {senseParamsData && chartData.series.length > 0 && !isLoading ? (
                                                <ReactApexChart
                                                    options={chartData.options}
                                                    series={chartData.series}
                                                    type="line"
                                                    height={350}
                                                    width="100%"
                                                    style={{overflow: 'hidden'}}
                                                />
                                            ) : (
                                                <div style={{textAlign: "center", padding: "150px"}}>
                                                    <div style={{textAlign: 'center', padding: '20px'}}>
                                                        Carregando o gráfico, por favor aguarde...
                                                    </div>
                                                    <br/>
                                                    <Spin
                                                        indicator={
                                                            <LoadingOutlined
                                                                style={{fontSize: 68, color: "#B64CFF"}}
                                                                spin
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Col>
                    <Col span={7}>
                        <div
                            className="senseBasicSidePanel"
                            style={{
                                width: '100%',
                                position: 'relative',
                                padding: '10px',
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    right: 10,
                                    cursor: "pointer",
                                }}
                            >
                            </div>
                            <Tabs defaultActiveKey="1" centered tabBarGutter={45}>
                                <TabPane tab="Alertas" key="1">
                                    <Row>
                                        <Col span={24}>
                                            <AtypicalEvents notifications={[]} esg_detections={[]}/>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Row>
                                        <Col span={24}>
                                            <PlantingWindow
                                                zarcCompliance={zarcCompliance}
                                                dateHarvest={estimatedHarvestDate}
                                                dateSowing={estimatedSowingDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider/>

                                    <Row>
                                        <Col span={24}>
                                            <Notifications notifications={realAlerts}/>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tab="Dados Quinzenais" key="2">
                                    <Row
                                        style={{
                                            padding: "10px",
                                            borderRadius: token.borderRadiusLG,
                                            border: `1px solid ${token.colorBorderSecondary}`,
                                        }}
                                    >
                                        <Col span={24}>
                                            <Title
                                                style={{
                                                    lineHeight: "4px",
                                                    fontSize: "32px",
                                                    color: "#   ",
                                                    padding: "10px",
                                                    paddingTop: "0px",
                                                }}
                                            >
                                                Resumo Climático
                                            </Title>
                                            <Divider/>
                                            <Row>
                                                <Col>
                                                    <div className="infoSectionRainfallSum">
                                                        <CloudOutlined className="cloudIconLarge"/>
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Total de chuva dos últimos 15 dias
                                                            </Text>
                                                            <br/>
                                                            {typeof totalPrecipitation === 'number' ? totalPrecipitation.toFixed(2) : 'N/D'} mm
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="infoSectionMaxTemp"
                                                        style={{paddingTop: "20px"}}
                                                    >
                                                        <img
                                                            src="/icons/brightness_5.svg"
                                                            alt="Cloud Icon"
                                                            width={45}
                                                            height={45}
                                                        />
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Temperatura Máx dos últimos 15 dias
                                                            </Text>
                                                            <br/>
                                                            {typeof maxTempData === 'number' ? maxTempData.toFixed(2) : 'N/D'} ºC
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="infoSectionAvgTemp"
                                                        style={{paddingTop: "20px"}}
                                                    >
                                                        <img
                                                            src="/icons/device_thermostat.svg"
                                                            alt="Cloud Icon"
                                                            width={45}
                                                            height={45}
                                                        />
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Temperatura Méd dos últimos 15 dias
                                                            </Text>
                                                            <br/>
                                                            {typeof avgTempData === 'number' ? avgTempData.toFixed(2) : 'N/D'} ºC
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="infoSectionMinTemp"
                                                        style={{paddingTop: "20px"}}
                                                    >
                                                        <img
                                                            src="/icons/ac_unit.svg"
                                                            alt="Cloud Icon"
                                                            width={45}
                                                            height={45}
                                                        />
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Temperatura Min dos últimos 15 dias
                                                            </Text>
                                                            <br/>
                                                            {typeof minTempData === 'number' ? minTempData.toFixed(2) : 'N/D'} ºC
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="infoSectionMaxWind "
                                                        style={{paddingTop: "20px"}}
                                                    >
                                                        <img
                                                            src="/icons/wind_power.svg"
                                                            alt="Cloud Icon"
                                                            width={45}
                                                            height={45}
                                                        />
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Máxima dos ventos
                                                            </Text>
                                                            <br/>
                                                            {typeof maxWindSpeed === 'number' ? maxWindSpeed.toFixed(2) : 'N/D'} km/h
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "15px",
                                            padding: "10px",
                                            borderRadius: token.borderRadiusLG,
                                            border: `1px solid ${token.colorBorderSecondary}`,
                                        }}
                                    >
                                        <Col span={24}>
                                            <Title
                                                style={{
                                                    lineHeight: "4px",
                                                    fontSize: "32px",
                                                    color: "#   ",
                                                    padding: "10px",
                                                    paddingTop: "0px",
                                                }}
                                            >
                                                Uso da área
                                            </Title>

                                            <Divider/>

                                            <Row
                                                style={{
                                                    padding: "10px",
                                                    borderRadius: token.borderRadiusLG,
                                                    border: `1px solid ${token.colorBorderSecondary}`,
                                                }}
                                            >
                                                <Col span={24}>
                                                    <div className="infoSection">
                                                        <CloudOutlined className="cloudIconLarge"/>
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Área utilizada para plantio
                                                            </Text>
                                                            <br/>
                                                            <Text className="textInfo">
                                                                {typeof areaPercentage === 'number' && !isNaN(areaPercentage)
                                                                    ? `${areaPercentage.toFixed(2)}% da área do talhão`
                                                                    : 'N/D% da área do talhão'}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="infoSection"
                                                        style={{paddingTop: "15px"}}
                                                    >
                                                        <CloudOutlined className="cloudIconLarge"/>
                                                        <div>
                                                            <Text className="textInfoTitle">
                                                                Cultura identificada
                                                            </Text>
                                                            <br/>
                                                            {/*Once there is no way to identify the culture, remains hardcoded as "cultura não identificada*/}
                                                            <Text className="textInfo">Não identificada</Text>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Divider/>
                                    <Button
                                        className="precipitationChartButtonSenseBasic"
                                        type="primary"
                                        icon={<MenuFoldOutlined/>}
                                        onClick={showModal}
                                    >
                                        Visualizar gráfico de precipitação
                                    </Button>
                                    <Modal
                                        open={isModalOpen}
                                        onCancel={handleCancel}
                                        footer={null}
                                        width="60%"
                                    >
                                        <p>
                                            <div>
                                                <Row>
                                                    <Col span={24}>
                                                        <div>
                                                            <PrecipitationChart
                                                                datesArray={precipitationDate}
                                                                valuesArray={precipitationData}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </p>
                                    </Modal>
                                </TabPane>
                                <TabPane tab="ESG" key="3">
                                    <div
                                        style={{
                                            maxHeight: "1000px",
                                            overflowY: "auto",
                                            paddingBotton: "10px"
                                        }}
                                    >
                                        <ESGCardsList
                                            data={data?.risk_classification_records}
                                            isLoading={data?.risk_score ? data?.risk_score : null}
                                            sicar_overlaps={data?.sicar_overlaps}
                                        />
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SenseBasicReport;