import React from "react";
import { Card, Typography } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled
} from "@ant-design/icons";
import "./ESGCards.css";

const { Text } = Typography;

// Define a mapping from ESG category IDs to names
const esgMapping = {
  1: "Área de Proteção Permanente",
  // 2: "AREA_ALTITUDE_SUPERIOR_1800",
  // 3: "AREA_CONSOLIDADA",
  // 4: "AREA_DECLIVIDADE_MAIOR_45",
  // 5: "AREA_IMOVEL",
  // 6: "AREA_POUSIO",
  // 7: "AREA_TOPO_MORRO",
  8: "Banhado",
  // 9: "BORDA_CHAPADA",
  // 10: "HIDROGRAFIA",
  11: "Manguezal",
  // 12: "NASCENTE_OLHO_DAGUA",
  13: "Reserva legal",
  // 14: "Restinga",
  // 15: "Servidão administrativa",
  16: "Uso restrito",
  17: "Vegetação nativa",
  // 18: "Vereda",
  19: "Desmatamento PRODES",
  20: "Embargo ICMBIO",
  21: "Embargo IBAMA",
  22: "Assentamento",
  23: "Área indígena",
  // 42: "Trabalho infantil",
  // 43: "Trabalho escravo",
  24: "Sitio Arqueológico",
  99: "Sitio Arqueológico",
};

const AuraESGCards = ({ sicar_overlaps }) => {
  const environmentalStatus = Object.fromEntries(
    Object.entries(esgMapping).map(([id, name]) => [
      name,
      sicar_overlaps.some(
        (item) =>
          parseInt(item.id_esg) === parseInt(id) && item.intersect_status
      ),
    ])
  );

  const noDataAvailable = ["Trabalho infantil", "Trabalho escravo"];
  const sortedStatus = Object.entries(environmentalStatus).sort(
    ([, a], [, b]) => (a === b ? 0 : a ? -1 : 1)
  );

  return (
    <div className="auraEsgContainer">
      <div className="auraEsgCardTitle">
          Validação ESG
      </div>
      <div className="auraEsgContent">
        {sortedStatus.slice(0, 12).map(([key, value]) => (
            <div className="auraEsgCard" key={key}>
                {noDataAvailable.includes(key) ? (
                    <MinusCircleFilled
                        style={{color: "#00000040"}}
                        className="auraEsgCardIcon"
                    />
                ) : value ? (
                    <CloseCircleFilled
                        style={{color: "#F5222D"}}
                        className="auraEsgCardIcon"
                    />
                ) : (
                    <CheckCircleFilled
                        style={{color: "#52C41A"}}
                        className="auraEsgCardIcon"
                    />
                )}
                <span className="auraEsgCardText" data-full-text={key}>
                  {key}
                </span>
            </div>
        ))}
      </div>
    </div>
  );
};

export default AuraESGCards;
