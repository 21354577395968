import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./AuraMap.css";

const AuraMapGraph = ({ selectedChart, data, ndviData, classificationRecords }) => {
    const [chartData, setChartData] = useState({ series: [], options: {} });

    const lastRecord = classificationRecords.sort((a, b) => new Date(b.sowing_date) - new Date(a.sowing_date))[0];

    const graphIds = {
        ndvi: "plantHealth",
        om: "organicMatter",
        ndre: "nitrogen",
    };

    const graphTitles = {
        ndvi: "Saúde das plantas",
        om: "Gráficos de matéria orgânica",
        ndre: "Gráfico de nitrogênio",
    };

    useEffect(() => {
        const chartDataArray = selectedChart === 'ndvi' 
            ? ndviData.map(item => item.NDVI)
            : data.map(item => selectedChart === 'om' ? item.somi : item.ndre);

        const datesArray = selectedChart === 'ndvi'
            ? ndviData.map(item => new Date(item.date).getTime())
            : data.map(item => new Date(item.date).getTime());

        const options = {
            chart: {
                id: graphIds[selectedChart],
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            plotOptions: { bar: { columnWidth: '70%' } },
            dataLabels: { enabled: false },
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: (value) => formatDate(new Date(value)),
                    style: {
                        fontFamily: "Outfit",
                        fontSize: "12px",
                    },
                },
                tickAmount: Math.min(12, datesArray.length),
                tooltip: { enabled: false },
            },
            yaxis: {
                min: 0.0,
                max: 1.0,
                tickAmount: 7,
                labels: {
                    formatter: (value) => value.toFixed(1),
                    style: {
                        fontFamily: "Outfit",
                        fontSize: "12px",
                    },
                },
            },
            tooltip: {
                x: {
                    formatter: (value) => {
                        return value ? formatDate(new Date(value)) : 'Data inválida';
                    },
                },
                y: {
                    formatter: (val) => {
                        return val !== null ? `${val.toFixed(2)}` : 'N/D';
                    },
                },
            },
            title: {
                text: graphTitles[selectedChart],
                align: "left",
                style: {
                    fontFamily: 'Outfit',
                    fontSize: '24px',
                    color: '#333',
                    fontWeight: "500",
                },
            },
        };

        const colorOptions = {
            om: "#000000",
            ndre: "#BF21CD",
            ndvi: "#58C058"
        };

        const colors = [colorOptions[selectedChart]];

        setChartData({
            series: [{
                name: selectedChart.toUpperCase(),
                data: chartDataArray.map((value, index) => ({
                    x: datesArray[index],
                    y: value,
                })),
            }],
            options: { ...options, colors },
        });
    }, [selectedChart, data, ndviData]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
            width="100%"
            style={{ overflow: 'hidden' }}
        />
    );
};

export default AuraMapGraph;
