import React from 'react';
import ReactApexChart from 'react-apexcharts';

import "./Billing.css"

const AuraChart = ({ data }) => {
    const areaData = data.daily_area.map(entry => entry.total_area);

    const options = {
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#9236D9'],
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            min: 0,
            logarithmic: true,
            labels: {
                show: false
            }
        },
        grid: {
            show: false
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: false
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                }
            },
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                }
            }
        }
    };

    const series = [{
        data: areaData
    }];

    return (
        <div className='billingChartCard'>
            <div className='billingChartTitle'>Aura - análise de risco</div>
            <div className='billingChartNumber'>
                {Math.floor(data.area_total).toLocaleString('pt-BR')} Hec
            </div>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={80}
            />
        </div>
    );
};

export default AuraChart;
