import React, { useState, useEffect } from "react";
import { Result, Button, Popover } from "antd";
import { FrownFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "./ErrorBoundary.css";
import HelpPopout from "../components/layout/HelpPopout";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleErrors = (error) => {
      console.error("Error caught by ErrorBoundary: ", error);
      setHasError(true);
    };

    window.addEventListener("error", handleErrors);

    return () => {
      window.removeEventListener("error", handleErrors);
    };
  }, []);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleReportError = () => {
    console.log("Error reported!");
  };

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);

    if (pathSegments.length > 1) {
      pathSegments.pop();
      const newPath = `/${pathSegments.join("/")}`;
      window.location.href = newPath; // Redireciona e recarrega a página
    } else {
      window.location.href = "/"; // Redireciona para a home e recarrega a página
    }
  };

  if (hasError) {
    return (
      <Result
        className="errorBoundary"
        icon={<FrownFilled style={{ color: "#FF4D4F" }} />}
        title="A plataforma encontrou um erro"
        subTitle="Por favor, envie uma solicitação de suporte para que nosso time possa solucionar esse problema!"
        extra={[
          <Button className="buttonCustomWhite" key={"newForm"} onClick={handleBack}>
            Voltar
          </Button>,
          <Popover
            content={<HelpPopout closePopover={() => setOpen(false)} />}
            trigger="click"
            placement="right"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button className="buttonCustom" key={"seeHistory"} onClick={() => setOpen(!open)}>
              Reportar um erro
            </Button>
          </Popover>,
        ]}
      />
    );
  }

  return children;
};

export default ErrorBoundary;
