import React from "react";
import TopFrame from "./TopFrame";
import Sidebar from "./Sidebar";
import Breadcrumbs from "./Breadcrumbs";
import "./Layout.css";

function Layout({ children }) {
  return (
    <>
      <TopFrame />
      <Sidebar />
      <div className="appContainer">
        <Breadcrumbs />
        {children}
      </div>
    </>
  );
}

export default Layout;
