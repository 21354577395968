import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { bbox as turfBbox } from "@turf/turf";
import "leaflet/dist/leaflet.css";
import "./VisionDashboardMap.css";
import { polygonStylePatterns } from "../../pages/polyStyles";

const styleByFeature = (feature) => {
  if (feature.properties?.predicted) {   // Cropscan
    switch (String(feature.properties.predicted)) {
      case '204':
        return polygonStylePatterns.sojaArea;
      case '3':
        return polygonStylePatterns.milhoArea;
      case '4':
        return polygonStylePatterns.outroArea;
      default:
        return polygonStylePatterns.outroArea;
    }
  }
  if (feature.properties?.app) {  // CAR ou ESG
    if (feature.properties.app === "AREA_IMOVEL_1") {
      return polygonStylePatterns.carArea;  // CAR
    }
    return polygonStylePatterns.esgArea;  // ESG
  }
  return polygonStylePatterns.kmlArea;  // Default
};

function UpdateMapBounds({ geoJsonData }) {
  const map = useMap();

  useEffect(() => {
    if (geoJsonData && geoJsonData.features.length > 0) {
      const bounds = turfBbox(geoJsonData);
      map.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  }, [geoJsonData, map]);

  return null;
}

function VisionDashboardMap({ carData, esgData, cropscanData, layerVisibility }) {
  const [geoJsonCar, setGeoJsonCar] = useState(null);
  const [geoJsonEsg, setGeoJsonEsg] = useState(null);
  const [geoJsonCropscan, setGeoJsonCropscan] = useState(null);

  const processGeoJsonData = (data) => {
    if (data?.features && data.features.length > 0) {
      const validFeatures = data.features.map((feature) => ({
        type: "Feature",
        ...feature,
      }));

      return {
        type: "FeatureCollection",
        features: validFeatures,
      };
    }
    return null;
  };

  useEffect(() => {
    setGeoJsonCar(processGeoJsonData(carData));
  }, [carData]);

  useEffect(() => {
    setGeoJsonEsg(processGeoJsonData(esgData));
  }, [esgData]);

  useEffect(() => {
    setGeoJsonCropscan(processGeoJsonData(cropscanData));
  }, [cropscanData]);

  return (
    <div className="mapVis">
      <MapContainer
        center={[-16, -50]}
        zoom={5}
        minZoom={5}
        maxZoom={16}
        zoomControl={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />

        {layerVisibility.CAR && geoJsonCar && <GeoJSON data={geoJsonCar} style={styleByFeature} />}
        {layerVisibility.ESG && geoJsonEsg && <GeoJSON data={geoJsonEsg} style={styleByFeature} />}
        {layerVisibility.CROPSCAN && geoJsonCropscan && <GeoJSON data={geoJsonCropscan} style={styleByFeature} />}


        {geoJsonCar && <UpdateMapBounds geoJsonData={geoJsonCar} />}
        {geoJsonEsg && <UpdateMapBounds geoJsonData={geoJsonEsg} />}
        {geoJsonCropscan && <UpdateMapBounds geoJsonData={geoJsonCropscan} />}
      </MapContainer>
    </div>
  );
}

export default VisionDashboardMap;
