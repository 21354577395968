import React from "react";
import "./Aura.css";
import {
    AgricultureOutlined,
    CloudOutlined,
    DateRangeOutlined,
    EventBusyOutlined,
    GrassOutlined,
    WaterDropOutlined,
} from "@mui/icons-material";
import dayjs from 'dayjs';

const validCultures = ["soja", "milho", "maize"];

const AuraClimateAnalysis = ({ data, crop }) => {
    const formatDays = (days) => {
        if (!days || days == null) return "N/D";
        if (days === 1) return `${days} dia`;
        return `${days} dias`;
    };

    const isValidCulture = validCultures.includes(crop);

    return (
        <div className="auraClimateAnalysisContainer">
            <div className="auraClimateAnalysisCardTitle">
                Análise climática
            </div>
            <div className="auraClimateAnalysisContent">
                
                {/* Renderizar apenas para culturas válidas */}
                {isValidCulture && (
                    <>
                        <div className="auraClimateAnalysisSection">
                            <GrassOutlined style={{ fontSize: 48, color: "#00000040" }} />
                            <div>
                                <div className="auraClimateAnalysisTitle">Plantio identificado</div>
                                <div className="auraClimateAnalysisValue">
                                    {dayjs(data.estimated_sowing_date).format("DD/MM/YYYY")}
                                </div>
                            </div>
                        </div>

                        <div className="auraClimateAnalysisSection">
                            <AgricultureOutlined style={{ fontSize: 48, color: "#00000040" }} />
                            <div>
                                <div className="auraClimateAnalysisTitle">Colheita identificada</div>
                                <div className="auraClimateAnalysisValue">
                                    {dayjs(data.estimated_harvest_date).format("DD/MM/YYYY")}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* Itens sempre visíveis */}
                <div className="auraClimateAnalysisSection">
                    <CloudOutlined style={{ fontSize: 48, color: "#00000040" }} />
                    <div>
                        <div className="auraClimateAnalysisTitle">Chuva na safra</div>
                        <div className="auraClimateAnalysisValue">
                            {`${data?.total_rainfall != null ? data.total_rainfall.toFixed(1).replace('.', ',') : "N/D"} mm - ${formatDays(data?.total_season_days)}`}
                        </div>
                    </div>
                </div>
                
                <div className="auraClimateAnalysisSection">
                    <WaterDropOutlined style={{ fontSize: 48, color: "#00000040" }} />
                    <div>
                        <div className="auraClimateAnalysisTitle">Maior janela de chuva</div>
                        <div className="auraClimateAnalysisValue">{`${data?.longest_rainfall_window} dias`}</div>
                    </div>
                </div>
                
                <div className="auraClimateAnalysisSection">
                    <DateRangeOutlined style={{ fontSize: 48, color: "#00000040" }} />
                    <div>
                        <div className="auraClimateAnalysisTitle">Dias totais sem chuva</div>
                        <div className="auraClimateAnalysisValue">{`${data?.total_days_without_rain} dias`}</div>
                    </div>
                </div>
                
                <div className="auraClimateAnalysisSection">
                    <EventBusyOutlined style={{ fontSize: 48, color: "#00000040" }} />
                    <div>
                        <div className="auraClimateAnalysisTitle">Maior janela de seca</div>
                        <div className="auraClimateAnalysisValue">{`${data?.longest_dry_window} dias`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuraClimateAnalysis;
