import React, { useState, useEffect } from 'react';
import NewAuraReport1 from './AuraNormalRes'; // Importa a primeira versão da página
import NewAuraReport2 from './AuraSmallRes'; // Importa a segunda versão da página

const NewAuraReport = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1684);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1684);
    };

    // Adiciona o evento para monitorar redimensionamento da janela
    window.addEventListener('resize', handleResize);
    handleResize(); // Chamada inicial para verificar o tamanho da tela

    // Remove o evento ao desmontar o componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
    <div>
      {isLargeScreen ? <NewAuraReport1 /> : <NewAuraReport2 />}
    </div>
  );
}

export default NewAuraReport;
