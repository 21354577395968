import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Popup,
  Pane,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./SensePlusMap.css";
import SensePlusPopover from "./SensePlusPopover";
import { MonitorOutlined } from "@ant-design/icons";

// Fix default icon issues with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function SensePlusMap({ tickets }) {
  const [mapCenter, setMapCenter] = useState([-16, -50]);
  const navigate = useNavigate();

  useEffect(() => {
    if (tickets && tickets.length > 0) {
      const centerData = [
        tickets[0].centerCoords.lat,
        tickets[0].centerCoords.long,
      ];
      setMapCenter(centerData);
    }
  }, [tickets]);

  const getCircleColor = (sicar_overlap, notifications) => {
    const filteredOverlap = sicar_overlap.filter(
      (overlap) => ![3, 5].includes(overlap.id_esg)
    );
    if (filteredOverlap.length > 0) return "#F5222D";
    return notifications.length === 0 ? "#2288B4" : "#FF7B00";
  };

  return (
    <div className="mapVis">
      <MapContainer
        center={mapCenter}
        zoom={5}
        minZoom={5}
        maxZoom={16}
        zoomControl={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
        <Pane name="clickable-markers" style={{ zIndex: 400 }}>
          {tickets.map((ticket) => (
            <React.Fragment key={ticket.id}>
              <CircleMarker
                center={[ticket.centerCoords.lat, ticket.centerCoords.long]}
                color={"#FFF"}
                fillColor={getCircleColor(
                  ticket.sicar_overlap,
                  ticket.notifications
                )}
                fillOpacity={1}
                weight={0.5}
                radius={6}
                pane="clickable-markers"
              >
                <Popup className="sensePlusPopup" pane="popupPane">
                  <div className="sensePlusPopupContainer">
                    <SensePlusPopover ticket={ticket} />
                    <Button
                      className="sensePlusPopupButton"
                      icon={<MonitorOutlined />}
                      onClick={() => navigate(`/sense-basic/${ticket.id}`)}
                    >
                      Ver no monitoramento
                    </Button>
                  </div>
                </Popup>
              </CircleMarker>

              <CircleMarker
                center={[ticket.centerCoords.lat, ticket.centerCoords.long]}
                color={"transparent"}
                fillOpacity={0}
                weight={0}
                radius={10}
                pane="clickable-markers"
              >
                <Popup className="sensePlusPopup" pane="popupPane">
                  <div className="sensePlusPopupContainer">
                    <SensePlusPopover ticket={ticket} />
                    <Button
                      className="sensePlusPopupButton"
                      icon={<MonitorOutlined />}
                      onClick={() => navigate(`/sense-basic/${ticket.id}`)}
                    >
                      Ver no monitoramento
                    </Button>
                  </div>
                </Popup>
              </CircleMarker>
            </React.Fragment>
          ))}
        </Pane>
      </MapContainer>
    </div>
  );
}

export default SensePlusMap;
