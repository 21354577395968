import React, { useState, useEffect } from 'react';
import { Typography, Space } from 'antd';
import ReactApexCharts from 'react-apexcharts';

const { Text } = Typography;

const chartContainerStyle = {
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '6px',
  padding: '8px',
};

const formatDate = (dateString) => {
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return `${day}/${month}`;
};

const PrecipitationChart = ({ datesArray, valuesArray }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    if (Array.isArray(datesArray)) {
      const formattedDates = datesArray.map(date => formatDate(date));

      setChartData({
        series: [
          {
            name: 'Volume de Chuva',
            data: valuesArray,
          },
        ],
        options: {
          chart: {
            id: 'daily-scores-bar',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '70%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: formattedDates,
          },
          grid: {
            borderColor: '#90A4AE',
            strokeDashArray: 5,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val} mm`;
              },
            },
          },
        },
      });
    }
  }, [datesArray, valuesArray]);

  return (
    <div id="chart" style={chartContainerStyle}>
      <Space
        direction="horizontal"
        justify="space-between"
        align="center"
        style={{ width: '100%', padding: '0px 10px' }}
      >
        <Text className='plantingWindowTitle'>
          Gráfico de precipitação
        </Text>
      </Space>
      <ReactApexCharts
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={'332px'}
      />
    </div>
  );
};

export default PrecipitationChart;
