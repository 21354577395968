import React from "react";
import Status from "./Status";
import MapIframe from "./MapIframe";

import "./FarmInfoSense.css";

const FarmInfoSense = ({
  farmName,
  status,
  cropType,
  farmerName,
  farmLocation,
  ticketNumber,
  hectares,
  subscriber,
  date,
  mapImg,
}) => (
  <div className="senseFarmInfoContainer">
    <div className="senseFarmInfoMapContainer">
      <MapIframe mapImg={mapImg} />
    </div>

    <div className="senseFarmInfoTextContainer">
      <div className="senseFarmInfoTextColumn">
        <p className="senseFarmInfoTextTitle">ID do Cliente:</p>
        <p className="senseFarmInfoText">{farmerName}</p>
        <p className="senseFarmInfoTextTitle">Fazenda:</p>
        <p className="senseFarmInfoText">{farmName}</p>
        <p className="senseFarmInfoTextTitle">Local:</p>
        <p className="senseFarmInfoText">{farmLocation}</p>
        <p className="senseFarmInfoTextTitle">Cultura:</p>
        <p className="senseFarmInfoText">{cropType}</p>
        <p className="senseFarmInfoTextTitle">Área:</p>
        <p className="senseFarmInfoText">{Math.floor(hectares)} Hectares</p>
      </div>

      <div className="vl"></div>

      <div className="senseFarmInfoTextColumn">
        <div>
          <p className="senseFarmInfoTextTitle">Status:</p>
          <div className="senseFarmInfoTextStatus" style={{color: status.color}}>
            {status.label}
          </div>
        </div>
        <p className="senseFarmInfoTextTitle">Usuário:</p>
        <p className="senseFarmInfoTextPurple">{subscriber}</p>
        <p className="senseFarmInfoTextTitle">Gerado em:</p>
        <p className="senseFarmInfoText">{date}</p>
        <p className="senseFarmInfoTextTitle">Nº do Ticket:</p>
        <p className="senseFarmInfoTextPurple">{ticketNumber}</p>
      </div>
    </div>
  </div>
);

export default FarmInfoSense;
