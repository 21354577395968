import React, { useState, useEffect } from "react";
import SensePlusMap from "../components/sensePlus/sensePlusMap";
import { fetchSensePlusTickets } from "../components/api/api";

import "./SensePlus.css";
import { Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function SensePlus() {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [filteredEsgTickets, setFilteredEsgTickets] = useState([]);
  const [filteredAlertTickets, setFilteredAlertTickets] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedState, setSelectedState] = useState("Todos");
  const [stateOptions, setStateOptions] = useState([
    { value: "Todos", label: "Todos" },
  ]);

  useEffect(() => {
    const fetchAllTickets = async () => {
      let page = 1;
      let result;

      do {
        result = await fetchSensePlusTickets(page);
        const filteredResults = await Promise.all(
          result.results.map(async (ticket) => {
            return {
              id: ticket.id,
              clientId: ticket.farmer_name,
              proposalId: ticket.proposal_id,
              area: ticket.hectares,
              state: ticket.state,
              sicar_overlap: ticket.sicar_overlaps,
              centerCoords: ticket.center_coords,
              notifications: ticket.atypical_events,
            };
          })
        );

        setTickets((prevTickets) => [
          ...prevTickets,
          ...filteredResults.filter((ticket) => ticket !== null),
        ]);

        if(!result.next) setHasMore(false);

        page++;
      } while (result.next);
    };

    fetchAllTickets();
  }, []);

  useEffect(() => {
    const filtered =
      selectedState === "Todos"
        ? tickets
        : tickets.filter((ticket) => ticket.state === selectedState);

    setFilteredTickets(filtered);

    const esgFiltered = filtered.filter((ticket) => {
      const filteredOverlap = ticket.sicar_overlap.filter(
        (overlap) => ![3, 5].includes(overlap.id_esg)
      );
      return filteredOverlap.length > 0;
    });
    setFilteredEsgTickets(esgFiltered);

    const alertFiltered = filtered.filter((ticket) => ticket.notifications.length > 0);
    setFilteredAlertTickets(alertFiltered);
  }, [selectedState, tickets]);

  useEffect(() => {
    const states = tickets
      .map((ticket) => ticket.state)
      .filter((state) => state !== null && state !== undefined);
    const uniqueStates = Array.from(new Set(states)).map((state) => ({
      value: state,
      label: state,
    }));
    setStateOptions([{ value: "Todos", label: "Todos" }, ...uniqueStates]);
  }, [tickets]);

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <div className="sensePlusFilterCard">
        <div className="sensePlusFilterHeader">
          <div className="sensePlusFilterTitle">
            Filtrar dados
            {hasMore && <Spin
              indicator={<LoadingOutlined
                  style={{
                      fontSize: 20,
                      color: "#ffffffd9",
                  }}
                  spin
              />}
            />}
          </div>
          <Select
            className="sensePlusStateSelect"
            placeholder="Estado"
            onChange={(value) => setSelectedState(value)}
            options={stateOptions}
          />
        </div>
        <div className="sensePlusFilterContent">
          <div>
            <span style={{color: "#1890FF"}}>Contratos Monitorados:</span>{" "}
            {filteredTickets.length}
          </div>
          <div>
            <span style={{color: "#FAAD14"}}>Contratos em Alerta:</span>{" "}
            {filteredAlertTickets.length}
          </div>
          <div>
            <div style={{color: "#FF4D4F"}}>Contratos com ESG:</div>{" "}
            {filteredEsgTickets.length}
          </div>
        </div>
      </div>
      <SensePlusMap tickets={filteredTickets} />
    </div>
  );
}

export default SensePlus;
