import { React } from "react";
import { Button, Popconfirm } from "antd";
import { updateTicketStatus } from "../api/api";
import { CheckOutlined,
  StopOutlined,
  PrinterOutlined
} from "@ant-design/icons";

const DashboardHeaderButtons = ({ ticket, onStatusChange }) => {
  const handleApprove = (ticket) => async () => {
      try {
        await updateTicketStatus(ticket, "A");
        onStatusChange("A");
      } catch (error) {
        console.error("Error approving ticket:", error);
    }
  };
  
  const handleReprove = (ticket) => async () => {
    try {
      await updateTicketStatus(ticket, "R");
      onStatusChange("R");
    } catch (error) {
      console.error("Error reproving ticket:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="auraHeaderButtons">
      {(ticket.aura_status === true &&
        ticket.consultation_status  === 'P' &&
        ticket.risk_calculation_status === "success") &&
        ticket.risk_score > 0 && (
        <>
          <Popconfirm
            title="Tem certeza que deseja reprovar esse ticket?"
            description="Esta ação não é reversível."
            onConfirm={handleReprove(ticket)}
            okText="Sim"
            cancelText="Não"
            placement="bottom"
          >
            <Button
              className="reproveButton"
              onClick={(e) => e.stopPropagation()}
              type="primary"
              ghost
              icon={<StopOutlined />}
            >
              Reprovar
            </Button>
          </Popconfirm>
          <Button
            className="approveButton"
            onClick={handleApprove(ticket)}
            type="primary"
            style={{defaultHoverBg: "#000"}}
            icon={<CheckOutlined />}
          >
            Aprovar
          </Button>
        </>
      )}
      <Button
        className="pdfButton"
        type="primary"
        icon={<PrinterOutlined />}
        onClick={handlePrint}
      >
        Gerar PDF
      </Button>
    </div>
  );
};

export default DashboardHeaderButtons;
