import { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Spin } from "antd";

import MapMarkers from "../dashboard/functions/GetMapMarkers";

import FilterButton from "./FilterButton";

import { fetchMapContext } from "../api/api";
import { LoadingOutlined } from "@ant-design/icons";

import "./MapboxWithFilters.css";
import MapErrorPopover from "../../pages/MapErrorPopover";

// Fix default icon issues with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Legend = ({ imageUrl }) => {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML = `<img src=${imageUrl} alt="Legenda" style="width: 36px;">`;
      return div;
    };

    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map, imageUrl]);

  return null;
};

function MapBoxWithFilters({ id, coords }) {
  const [selectedOption, setSelectedOption] = useState("ndvi");
  const [mapColors, setMapColors] = useState([0, 0]);
  const [mapData, setMapData] = useState([]);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [loading, setLoading] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [retry, setRetry] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMapCenter([coords[0], coords[1]]);

    if (retry == 0) handleSelectorChange("ndvi");

    if (retry >= 1 && retry <= 3 && error == true) {
      const timeout = setTimeout(async () => {
        try {
          await handleSelectorChange(selectedOption);
        } catch (error) {
          console.error("Error in handleSelectorChange:", error);
        }
      }, 20000);

      return () => clearTimeout(timeout);
    }
  }, [retry, error]);

  const handleSelectorChange = async (optionValue) => {
    let hasError = false;

    if (optionValue != selectedOption) setRetry(1);

    setLoading(true);
    setSelectedOption(optionValue);

    try {
      const processedData = await fetchMapContext(optionValue, id);

      const updatedFeatures = processedData.geojson_data.features.map((feature) => {
        if (feature.properties.elevation === 0) {
          feature.properties.elevation = 0.000001;
        }
        return feature;
      });

      setMapData(processedData.geojson_data.features);
      setMapColors(processedData.color_map);
      setMapCenter([processedData.map_center[1], processedData.map_center[0]]);
      setMapKey((prevKey) => prevKey + 1);

      setError(false);
    } catch (error) {
      hasError = true;
      setRetry((retry) => retry + 1);
      setError(true);
      console.error("Failed to fetch map context:", error);
    } finally {
      if (!hasError) setLoading(false);
    }
  };

  const getMessage = () => {
    return buttonOptions.length === 0 ? <p>No option found</p> : null;
  };

  const legendPathOption = {
    om: "/assets/mapLegends/OrgMatter.svg",
    ndvi: "/assets/mapLegends/Plant-Health.svg",
    ndre: "/assets/mapLegends/Nitrogen.svg",
    ndmi: "/assets/mapLegends/Watar.svg",
    gci: "/assets/mapLegends/Plant-Stress.svg",
  };

  const buttonOptions = [
    {
      value: "om",
      label: "Matéria Orgânica",
      icon: "organic-material.svg",
    },
    {
      value: "ndvi",
      label: "Plant Health",
      icon: "plant-health.svg",
    },
    {
      value: "ndre",
      label: "Nitrogênio",
      icon: "nitrogen.svg",
    },
    {
      value: "ndmi",
      label: "Água Disponível",
      icon: "water-stress.svg",
    },
    {
      value: "gci",
      label: "Plant Stress",
      icon: "plant-stress.svg",
    },
  ];

  return (
    <div className="mapboxContainer">
      <div className="mapBoxVis" style={{ position: "relative" }}>
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    color: "#B64CFF",
                    fontSize: "40px",
                  }}
                  spin
                />
              }
            />
          </div>
        )}
        <MapContainer
          key={mapKey}
          center={mapCenter}
          zoom={15}
          maxZoom={16}
          minZoom={14}
          zoomControl={false}
          style={{ height: "100%", width: "100%", borderRadius: "6px" }}
        >
          <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {mapData.length ? (
            <div>
              <MapMarkers
                option={selectedOption}
                data={mapData}
                colors={mapColors}
              />
            <Legend imageUrl={legendPathOption[selectedOption]} /> 
            </div>
          ) 
            : (<div></div>)}
        </MapContainer>

        {error && retry > 3 && <MapErrorPopover />}
      </div>
      {getMessage()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "4px 4px 4px 0",
          gap: 4,
        }}
      >
        {buttonOptions.map((option) => (
          <FilterButton
            key={option.value}
            label={option.label}
            value={option.value}
            filteredBtn={selectedOption}
            setFilter={handleSelectorChange}
            iconName={option.icon}
            loading={loading && selectedOption === option.value}
          />
        ))}
      </div>
    </div>
  );
}

export default MapBoxWithFilters;
