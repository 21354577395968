import React, { useState, useEffect } from "react";
import { Select, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactApexCharts from "react-apexcharts";

import "./RainfallToPlantHealthChart.css"

const { Text } = Typography;

const RainfallToPlantHealthChart = ({ data }) => {
  const [selectedSafra, setSelectedSafra] = useState("");
  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    if (data && data.length > 0 && !selectedSafra) {
      setSelectedSafra(data[0].safraLabel);
    }
  }, [data, selectedSafra]);

  useEffect(() => {
    const filteredData = data.find(
      (safra) => safra.safraLabel === selectedSafra
    );
    if (filteredData && filteredData.data && filteredData.data.length > 0) {
      setChartData({
        series: [
          {
            name: "Total Mensal de Chuva",
            type: "column",
            data: filteredData.data.map((item) => item.value),
          },
          {
            name: "Saúde das Plantas",
            type: "line",
            data: filteredData.data.map((item) => item.ndvi || 0.1),
          },
        ],
        options: {
          chart: {
            id: "line-column-combo",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            }
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          stroke: {
            width: 3,
          },
          xaxis: {
            categories: filteredData.data.map((item) => item.date),
          },
          yaxis: [
            {
              title: {
                text: "Total Mensal de Chuva (mm)",
              },
              labels: {
                formatter: (value) => `${value} mm`,
              },
            },
            {
              opposite: true,
              title: {
                text: "Saúde das Plantas (NDVI)",
              },
              labels: {
                formatter: (value) => value ? value.toFixed(2) : '',
              },
            },
          ],
        },
      });
    }
  }, [selectedSafra, data]);

  return (
    <div className="RainfallToPlantHealthContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 16,
        }}
      >
        <Text
          style={{
            color: "#B64CFF",
            fontSize: "24px",
            fontWeight: "700",
            paddingLeft: "16px",
          }}
        >
          Visualização em gráfico
        </Text>
        <div style={{ display: "flex", gap: "16px" }}>
          {/* <Tooltip title="Para soja, recomenda-se selecionar períodos de setembro à setembro, para visualizar as informações mais relevantes">
            <InfoCircleOutlined style={{ fontSize: "24px" }} />
          </Tooltip> */}
          <Select
            value={selectedSafra}
            onChange={setSelectedSafra}
            style={{ width: "200px" }}
          >
            {data &&
              data.map((safra) => (
                <Select.Option key={safra.safraLabel} value={safra.safraLabel}>
                  {safra.safraLabel}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>
      <div style={{ minHeight: "360px", padding: 16 }}>
        <ReactApexCharts
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={"100%"}
        />
      </div>
    </div>
  );
};

export default RainfallToPlantHealthChart;
