import React from "react";
import "./TopFrame.css";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

function TopFrame() {
  const handleLogout = () => {
    const deleteCookie = (name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    deleteCookie("auth-token");
    deleteCookie("user-id");
    deleteCookie("email");
    deleteCookie("role");

    localStorage.clear();
    sessionStorage.clear();

    window.location.href = "/login";
  };

  return (
    <div className="TopFrame">
      <div className="topFrameLogoContainer">
        <img
          src="/assets/logo-01-white.svg"
          alt="Agromai logo"
        />
      </div>
      <div className="topBarLogout">
        <Button 
          className="logoutButton"
          type="ghost"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >Logout</Button>
      </div>
    </div>
  );
}

export default TopFrame;
