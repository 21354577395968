import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";

const MapIframe = ({ mapImg }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!mapImg || mapImg === "");
  }, [mapImg]);

  if (loading) {
    return <Skeleton.Image className="flexSkelly" active />;
  }

  return (
    <iframe
      title="Map Visualization"
      style={{
        width: "100%",
        height: "100%",
        border: "none",
        overflow: "hidden",
        borderRadius: "4px 4px 0px 0px",
      }}
      srcDoc={`<!DOCTYPE html><html><head><style>
        body {
          margin: 0;
          border-radius:6px;
        }
        </style></head><body>
        <div style="display: flex; justify-content: center; align-items: center; height: 148px; width: 100%;">${mapImg}</div>
        </body></html>`}
      scrolling="no"
    />
  );
};

export default MapIframe;
