function classifyByHarvestYear(year) {
  const yearClassification = {
    2024: "Ainda não registrado.",
    2023: "El Niño",
    2022: "La Niña",
    2021: "La Niña",
    2020: "El Niño",
    2019: "El Niño",
  };

  return yearClassification[year];
}
export default classifyByHarvestYear;
