import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import {
  LeftOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import formatDateSimple from "./functions/formatDateSimple";
import { fetchSenseDatedReports } from "../api/api";

const ReportDateSelector = ({ KMLID, onDateChange }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const loadReports = async () => {
      try {
        const response = await fetchSenseDatedReports(KMLID);
        const formattedReports = response.results.map((report) => ({
          ...report,
          report_date: formatDateSimple(report.report_date), // Format date upon fetching
        }));
        setReports(formattedReports);
        if (formattedReports.length > 0) {
          setCurrentDate(formattedReports[0].report_date);
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch reports:", error);
        setLoading(false);
      }
    };

    loadReports();
  }, [KMLID]);

  const handleDateChange = (shift) => {
    const currentIndex = reports.findIndex(
      (report) => report.report_date === currentDate
    );
    const newIndex = currentIndex + shift;
    if (newIndex >= 0 && newIndex < reports.length) {
      setCurrentDate(reports[newIndex].report_date);
      onDateChange(reports[newIndex]); // Update outside state to reflect formatted date
    }
  };

  const handleSelectDate = (value) => {
    setCurrentDate(value);
    const selectedReport = reports.find(
      (report) => report.report_date === value
    );
    onDateChange(selectedReport);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          icon={<DoubleLeftOutlined />}
          onClick={() => handleDateChange(-reports.length)}
          disabled={loading || currentDate === reports[0]?.report_date}
        />
        <Button
          icon={<LeftOutlined />}
          onClick={() => handleDateChange(-1)}
          disabled={loading || currentDate === reports[0]?.report_date}
        />
        <Select
          value={currentDate || "Carregando relatórios..."}
          style={{ width: 120 }}
          onChange={handleSelectDate}
          disabled={loading}
          notFoundContent={loading ? "Loading..." : "No data"}
        >
          {reports.map((report) => (
            <Select.Option key={report.id} value={report.report_date}>
              {report.report_date}
            </Select.Option>
          ))}
        </Select>
        <Button
          icon={<RightOutlined />}
          onClick={() => handleDateChange(1)}
          disabled={loading || currentDate === reports[reports.length - 1]?.report_date}
        />
        <Button
          icon={<DoubleRightOutlined />}
          onClick={() => handleDateChange(reports.length)}
          disabled={loading || currentDate === reports[reports.length - 1]?.report_date}
        />
      </div>
    </div>
  );
};

export default ReportDateSelector;
