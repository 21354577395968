import ReactGA from 'react-ga4';
const GA_TRACKING_ID = process.env.REACT_APP_DEBUG ? 'G-8G1N15W7QQ' : 'G-ED3VMLPRCD';
const TRACKING_ID = GA_TRACKING_ID;

ReactGA.initialize(TRACKING_ID, {
  gaOptions: {
    cookieExpires: 63072000,
    cookieFlags: "SameSite=None; Secure"
  }
});

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search
  })
};

export const logEvent = (category, action) => {
  ReactGA.event({
    category,
    action
  });
};
