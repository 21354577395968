import React, { useState, useEffect } from "react";
import { Card, Typography, Select, Flex, Skeleton } from "antd";
import ReactApexChart from "react-apexcharts";
const { Text } = Typography;
const { Option } = Select;

const WindSpeedChart = ({ data }) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [chartSeries, setSeries] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const sortedYears = data.map((d) => d.year).sort((a, b) => b - a);
      const latestYear = sortedYears[0];
      setSelectedYear(latestYear);

      const latestYearData = data.find((d) => d.year === latestYear);
      setSeries([
        {
          name: `Velocidade do vento (Máx)`,
          data: latestYearData.data.map((monthData) => monthData.value),
        },
      ]);
    }
  }, [data]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    const yearData = data.find((d) => d.year === year);
    if (yearData) {
      setSeries([
        {
          name: `Velocidade do vento (Máx)`,
          data: yearData.data.map((monthData) => monthData.value),
        },
      ]);
    }
  };

  if (!data || data.length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;
  }

  const brazilianMonths = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: brazilianMonths,
    },
    yaxis: {
      labels: {
        formatter: (val) => `${val} Km/h`,
      },
    },
    stroke: {
      colors: ["#1E90FF"],
      width: 2,
    },
    markers: {
      size: 5,
      colors: ["#1E90FF"],
    },
  };

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#B64CFF",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              maxWidth: "calc(100% - 100px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Gráficos de velocidade dos ventos
          </Text>
          <Flex align="center">
            <div style={{ width: "8px" }}></div>
            <Select
              value={selectedYear}
              style={{ width: "100%" }}
              onChange={handleYearChange}
            >
              {data.map((yearData) => (
                <Option key={yearData.year} value={yearData.year}>
                  {yearData.year}
                </Option>
              ))}
            </Select>
          </Flex>
        </div>
      }
      bordered={true}
      style={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        flex: 1,
      }}
    >
      <ReactApexChart
        options={options}
        series={chartSeries}
        type="line"
        height={"320px"}
      />
    </Card>
  );
};

export default WindSpeedChart;
