import { React } from "react";
import "./sensePlusMap";
import atypicalEventsContent from "../dashboard/AtypicalEventsContent";
import { ExclamationCircleFilled, FlagFilled } from "@ant-design/icons";
import dayjs from "dayjs";

function getLastDay(notificationType, startDate) {
  let daysToAdd = 0;

  switch (notificationType) {
    case "THREE_DAY_HIGH":
    case "THREE_DAY_VERY_HIGH":
      daysToAdd = 2;
      break;
    case "FIVE_DAY_HIGH":
    case "FIVE_DAY_VERY_HIGH":
      daysToAdd = 4;
      break;
    case "DAILY_HIGH":
    case "DAILY_VERY_HIGH":
    default:
      daysToAdd = 0;
  }

  return dayjs(startDate).add(daysToAdd, 'day');
}

const SensePlusPopover = ({ ticket }) => {
  const hasAlert = (sicar_overlap) => {
    return sicar_overlap.some(overlap => ![3, 5].includes(overlap.id_esg));
  };

  const sortedNotifications = [...ticket.notifications].sort((a, b) => {
    const lastDayA = getLastDay(a.notification_type, a.notification_date);
    const lastDayB = getLastDay(b.notification_type, b.notification_date);
    return lastDayB - lastDayA;
  });

  return (
    <div className="sensePlusMapPopoverInfo">
      <div style={{display: "flex", flexDirection: "row", gap: "4px", overflow: "hidden"}}>
        <div className="sensePlusProposalIdTitle">ID do cliente:</div>
        <div className="sensePlusProposalId">{ticket.clientId}</div>
      </div>
      <div style={{display: "flex", flexDirection: "row", overflow: "hidden"}}>
        <div className="sensePlusProposalIdTitle" style={{minWidth: "100px"}}>ID da proposta:</div>
        <div className="sensePlusProposalId">{ticket.proposalId}</div>
      </div>
      <div className="sensePlusPopupArea">Área: {Math.floor(ticket.area)} Hectares</div>
      <div>
        {
          (sortedNotifications.length > 0) &&
          <div className="sensePopupInfoText">
            <ExclamationCircleFilled style={{color: "#FFA940"}} />
            {atypicalEventsContent[sortedNotifications[0].notification_type].title}
          </div>
        }
        {
          hasAlert(ticket.sicar_overlap) &&
          <div className="sensePopupInfoText">
            <FlagFilled style={{color: "#F5222D"}} />
            Detectado intersecção de ESG
          </div>
        }
      </div>
    </div>
  );
}

export default SensePlusPopover;
