import React from "react";
import { Button } from "antd";
import "./AuraHeader.css";
import getStatus from "../../components/dashboard/Status";
import getScoreInfo from "../../components/dashboard/functions/GetScoreInfo";
import formatDate from "../../components/dashboard/functions/formatDate";
import { PrinterOutlined } from "@ant-design/icons";

const getRiskScoreIcon = (ticket) => {  // TODO -> refatorar
  if (!ticket) return;
  let statusObj = getStatus(ticket);

  // If Total Classifications are 0 and Risk score calculation either goes through or not
  // Return N/D
  if (statusObj.status !== "calculating") {
    // Process this block only if ticket is not in calculating state
    if (
      (ticket.total_classifications === 0
          && (ticket.risk_calculation_status === "success" || ticket.risk_calculation_status === "error"))
          || (ticket.total_classifications === 0 && statusObj.status == "monitoring"))
        return (
      <div
        className="listingCardRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/D
      </div>
    );
  }

  switch (statusObj.status) {
    case "approved":
    case "inAnalysis":
    case "disapproved":
      let scoreInfo = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="auraHeaderRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo.color : '',
            color: ticket.risk_score == 0 ? scoreInfo.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/D"}
        </div>
      );
    case "calculating":
      if (ticket.consultation_status !== "M") {
        return <LoadingOutlined className="listingCardRiskScoreCalculating" />;
      } else
        return (
          <div
            className="auraHeaderRiskScore"
            style={{
              backgroundColor: "#FFF",
            }}
          />
        );
    case "monitoring":
      let scoreInfo2 = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="auraHeaderRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo2.color : '',
            color: ticket.risk_score == 0 ? scoreInfo2.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/D"}
        </div>
      );
    default:
      // Set the default state to N/D
      return <div
        className="auraHeaderRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/D
      </div>;
  }
};

const handlePrint = () => {
  window.print();
};

const AuraHeader = ({ ticket }) => {
  return (
    <div className="auraHeaderContainer">
        <div className="auraHeaderContent">
          {getRiskScoreIcon(ticket)}
          <div>
            <div className="auraHeaderField">ID do cliente:</div>
            <div className="auraHeaderValue">
              {ticket?.farm_data?.farmer?.farmer_name ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Fazenda:</div>
            <div className="auraHeaderValue">
              {ticket?.farm_data?.farm_name ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Talhão</div>
            <div className="auraHeaderValue">
              {ticket?.farm_data?.field_name ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">ID da propósta:</div>
            <div className="auraHeaderValue">
              {ticket?.farm_data?.proposal_id ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Local:</div>
            <div className="auraHeaderValue">
              {`${ticket?.farm_data?.kml_document?.kml_polygon?.municipality}, ${ticket?.farm_data?.kml_document?.kml_polygon?.state}`}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Cultura:</div>
            <div className="auraHeaderValue">
              {ticket?.farm_data?.crop_type.charAt(0).toUpperCase() + ticket?.farm_data?.crop_type.slice(1) ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Área:</div>
            <div className="auraHeaderValue">
              {`${(ticket?.land_size?.hectares).toFixed(2).replace('.', ',')} Ha` ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Serviço:</div>
            <div className="auraHeaderLastValues" style={{color: "#FAAD14"}}>
              Aura
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Usuário:</div>
            <div className="auraHeaderLastValues" style={{color: "#9236D9"}}>
              {ticket?.subwriter ?? ''}
            </div>
          </div>
          <div>
            <div className="auraHeaderField">Gerado em:</div>
            <div className="auraHeaderLastValues">
              {formatDate(ticket.created_at)}
            </div>
          </div>
        </div>
        <Button
          className="auraPdfButton"
          icon={<PrinterOutlined />}
          type="primary"
          onClick={handlePrint}
        >
            Gerar PDF
        </Button>
    </div>
  );
};

export default AuraHeader;
