import { React } from "react";
import {Button, Tooltip, Dropdown, Space} from "antd";
import {
  DeleteOutlined,
  ExportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  deleteUserTicket,
  deleteUserTicketFromListing,
} from "./api/api.js";

import "./CardComponent.css";
import getScoreInfo from "./dashboard/functions/GetScoreInfo.js";
import { parseCookies } from "nookies";
import getStatus from "./dashboard/Status.js";

const cookies = parseCookies();

const getRiskScoreIcon = (ticket) => {
  let statusObj = getStatus(ticket);

  // If Total Classifications are 0 and Risk score calculation either goes through or not
  // Return N/D
  if (statusObj.status !== "calculating") {
    // Process this block only if ticket is not in calculating state
    if (
      (ticket.total_classifications === 0
          && (ticket.risk_calculation_status === "success" || ticket.risk_calculation_status === "error"))
          || (ticket.total_classifications === 0 && statusObj.status == "monitoring"))
        return (
      <div
        className="listingCardRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/D
      </div>
    );
  }

  switch (statusObj.status) {
    case "approved":
    case "inAnalysis":
    case "disapproved":
      let scoreInfo = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="listingCardRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo.color : '',
            color: ticket.risk_score == 0 ? scoreInfo.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/D"}
        </div>
      );
    case "calculating":
      if (ticket.consultation_status !== "M") {
        return <LoadingOutlined className="listingCardRiskScoreCalculating" />;
      } else
        return (
          <div
            className="listingCardRiskScore"
            style={{
              backgroundColor: "#FFF",
            }}
          />
        );
    case "monitoring":
      let scoreInfo2 = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="listingCardRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo2.color : '',
            color: ticket.risk_score == 0 ? scoreInfo2.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/D"}
        </div>
      );
    default:
      // Set the default state to N/D
      return <div
        className="listingCardRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/D
      </div>;
  }
};

const getServiceInfo = (ticket) => {
  if (ticket.sense_status) {
    return  { color: "#1890FF", label: "Sense" };
  }
  if (ticket.vision_status) {
    return  { color: "#B64CFF", label: "Vision" };
  }
  if (ticket.aura_status) {
    return  { color: "#FAAD14", label: "Aura" };
  }
};

const CardComponent = ({ ticket, listUpdateOnDelete }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = (ticket) => async (event) => {
    event.stopPropagation();
    try {
      await deleteUserTicket(ticket.id);
      await deleteUserTicketFromListing(ticket.id)
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
    listUpdateOnDelete();
  };

  const basePath = location.pathname;

  const handleNavigation = (event, path) => {
    if (event.button !== 0) return;
  
    if (event.ctrlKey || event.metaKey) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };
  
  return (
    <div
      className="listingCard"
      onClick={(event) => handleNavigation(event, `${basePath}/${ticket.id}`)}
    >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {["MU"].includes(cookies["role"]) && (
            <Tooltip title="Excluir">
              <Button
                className="deleteTicketButton"
                type="ghost"
                onClick={handleDelete(ticket)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}
          {ticket?.aura_status === true && <div style={{ width: "48px" }}>
            {getRiskScoreIcon(ticket)}
          </div>}
          <div
            style={{ width: "200px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsisTitle">
              {ticket.farm_name}
            </div>
            <div className="textEllipsis">{ticket.field_name}</div>
          </div>

          <div
            style={{ width: "240px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div
              className="textEllipsisTitle"
              style={{ display: "flex", gap: 4 }}
            >
              Proposta:{" "}
              <div style={{ color: "#9236D9" }}>
                {ticket.proposal_id}
              </div>
            </div>
            <div className="textEllipsisGray">
              Cultura: {ticket.crop_type}
            </div>
          </div>

          <div
            style={{ width: "160px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsisTitle">
              {Math.floor(ticket.hectares)} Hectares
            </div>
            <div className="textEllipsisGray">
              {ticket.municipality}, {ticket.state}
            </div>
          </div>

          <div
            style={{ width: "128px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsis">Serviço</div>
            <div
              className="textEllipsisTitle"
              style={{
                color: getServiceInfo(ticket).color,
              }}
            >
              {getServiceInfo(ticket).label}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "32px", alignItems: "center" }}>
          <div className="listingCardButtonArea">
            <div style={{ display: "flex", gap: 8 }}>
              <Tooltip title="Abrir em nova aba">
                <Button
                  type="secondary"
                  danger
                  size="large"
                  icon={<ExportOutlined />}
                  className="approveDisapproveButton"
                  style={{ backgroundColor: "#f5f5f5" }}
                  href={`${basePath}/${ticket.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(event) => event.stopPropagation()}
                />
              </Tooltip>
            </div>
          </div>
          <div
            className="listingCardMap"
            dangerouslySetInnerHTML={{
              __html: ticket.map_html,
            }}
          />
        </div>
    </div>
  );
};

export default CardComponent;