import React, { useEffect } from "react";
import FarmInfoRiskChart from "./FarmInfoRiskChart";
import MapIframe from "./MapIframe";

import "./FarmInfo.css";


const FarmInfo = ({
  farmName,
  status,
  cropType,
  farmerName,
  farmLocation,
  ticketNumber,
  hectares,
  subscriber,
  date,
  mapImg,
  riskScore,
  totalClassifications
}) => {
  return (
    <div className="farmInfoContainer">
      <div className="farmInfoMapContainer">
        <MapIframe mapImg={mapImg} />
      </div>

      <div className="farmInfoRiskChartContainer">
        <FarmInfoRiskChart value={riskScore} status={status.status} totalClassifications={totalClassifications} />
      </div>

      <div className="farmInfoTextContainer">
        <div className="farmInfoTextColumn">
          <p className="farmInfoTextTitle">ID do Cliente:</p>
          <p className="farmInfoText">{farmerName}</p>
          <p className="farmInfoTextTitle">Fazenda:</p>
          <p className="farmInfoText">{farmName}</p>
          <p className="farmInfoTextTitle">Local:</p>
          <p className="farmInfoText">{farmLocation}</p>
          <p className="farmInfoTextTitle">Cultura:</p>
          <p className="farmInfoText">{cropType}</p>
          <p className="farmInfoTextTitle">Área:</p>
          <p className="farmInfoText">{Math.floor(hectares)} Hectares</p>
        </div>

        <div className="vl"></div>

        <div className="farmInfoTextColumn">
          <div>
            <p className="farmInfoTextTitle">Status:</p>
            <div className="farmInfoTextStatus" style={{color: status.color}}>
              {status.label}
            </div>
          </div>
          <p className="farmInfoTextTitle">Usuário:</p>
          <p className="farmInfoTextPurple">{subscriber}</p>
          <p className="farmInfoTextTitle">Gerado em:</p>
          <p className="farmInfoText">{date}</p>
          <p className="farmInfoTextTitle">Nº do Ticket: </p>
          <p className="farmInfoTextPurple">{ticketNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default FarmInfo;
