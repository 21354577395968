import React from "react";
import "./Aura.css";
import { Button } from "antd";
import { AutoAwesome } from "@mui/icons-material";

const validCultures = ["soja", "milho", "maize"];



const AuraInsights = ({ insights, onGenerateInsight, loadingInsights, crop }) => {
    const isValidCulture = validCultures.includes(crop);

    return (
        <div className="auraInsightsContainer">
          <div className="auraInsightsCardTitle">
            <AutoAwesome style={{ fontSize: 32, color: "#00000040" }} />
            AgromAI Insights - 23/24
          </div>
          <div className="auraInsightsContentHeader">
            Análise última safra
          </div>
          {insights ? (
            <div className="auraInsightsContent">
              {insights}
            </div>
          ) : (
            <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                paddingBottom: "32px",
            }}
        >
            <Button
                className="auraAnalysisInsightsButton"
                icon={<AutoAwesome style={{ fontSize: 16, verticalAlign: "center" }} />}
                loading={loadingInsights}
                onClick={onGenerateInsight}
                disabled={!isValidCulture} 
            >
                Gerar Insight
            </Button>
            
            {!isValidCulture && (
                <div style={{ marginTop: "16px", color: "#B64CFF", fontSize: "14px" }}>
                    Sem insight para a última safra.
                </div>
            )}
        </div>
    )}
</div>
);
};

export default AuraInsights;