import ReactApexChart from "react-apexcharts";
import { Card, Typography, Skeleton } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const { Text } = Typography;

dayjs.locale("pt-br");

const RainfallChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;

  }

  const series = data.map((yearData) => ({
    name: yearData.year.toString(),
    data: yearData.data.map((monthData) => monthData.value),
  }));

  const monthNames = {
    jan: "Janeiro",
    fev: "Fevereiro",
    mar: "Março",
    abr: "Abril",
    mai: "Maio",
    jun: "Junho",
    jul: "Julho",
    ago: "Agosto",
    set: "Setembro",
    out: "Outubro",
    nov: "Novembro",
    dez: "Dezembro"
  };
  
  const categories = data[0].data.map((monthData) =>
    monthNames[dayjs(monthData.date).format("MMM")]
  );

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontFamily: "Outfit",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
        style: {
          fontFamily: "Outfit",
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 0.85,
    },
    grid: {
      show: false
    },
    colors: ["#D3F261", "#1890FF", "#4ECB74", "#FBD437", "#9236D9", "#F2647B"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " mm";
        },
      },
    },
  };

  return (
    <div className="auraRainfallContainer">
      <div className="auraRainfallCardTitle">
        Indicies pluviométricos
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={200}
      />
    </div>
  );
};

export default RainfallChart;
