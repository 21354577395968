import React from "react";
import { Card, Typography } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled
} from "@ant-design/icons";
import "./ESGCards.css";

const { Text } = Typography;

// Define a mapping from ESG category IDs to names
const esgMapping = {
  1: "Área de Proteção Permanente",
  // 2: "AREA_ALTITUDE_SUPERIOR_1800",
  // 3: "AREA_CONSOLIDADA",
  // 4: "AREA_DECLIVIDADE_MAIOR_45",
  // 5: "AREA_IMOVEL",
  // 6: "AREA_POUSIO",
  // 7: "AREA_TOPO_MORRO",
  8: "Banhado",
  // 9: "BORDA_CHAPADA",
  // 10: "HIDROGRAFIA",
  11: "Manguezal",
  // 12: "NASCENTE_OLHO_DAGUA",
  13: "Reserva legal",
  // 14: "Restinga",
  // 15: "Servidão administrativa",
  16: "Uso restrito",
  17: "Vegetação nativa",
  // 18: "Vereda",
  19: "Desmatamento PRODES",
  20: "Embargo ICMBIO",
  21: "Embargo IBAMA",
  22: "Assentamento",
  23: "Área indígena",
  // 42: "Trabalho infantil",
  // 43: "Trabalho escravo",
  24: "Sitio Arqueológico",
  99: "Sitio Arqueológico",
};

const ESGCards = ({ sicar_overlaps }) => {
  const environmentalStatus = Object.fromEntries(
    Object.entries(esgMapping).map(([id, name]) => [
      name,
      sicar_overlaps.some(
        (item) =>
          parseInt(item.id_esg) === parseInt(id) && item.intersect_status
      ),
    ])
  );

  const noDataAvailable = ["Trabalho infantil", "Trabalho escravo"];

  return (
    <Card
      title={
        <Text style={{ color: "#B64CFF", fontSize: "24px", fontWeight: "600" }}>
          Verificação ESG
        </Text>
      }
      bordered={false}
      style={{
        minWidth: "100%",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <div className="esgCardContainer">
        {Object.entries(environmentalStatus)
          .slice(0, 12)
          .map(([key, value]) => (
            <Card key={key} className="esgCard" bordered={false}>
              <div className="esgCardContent" key={key}>
                {key}
                {noDataAvailable.includes(key) ? (
                  <MinusCircleFilled 
                    style={{ color: "#00000040" }}
                    className="esgCardIcon"
                  />
                ) : (
                  value ? (
                  <CloseCircleFilled
                    style={{ color: "#F5222D" }}
                    className="esgCardIcon"
                  />
                ) : (
                  <CheckCircleFilled
                    style={{ color: "#52C41A" }}
                    className="esgCardIcon"
                  />
                ))}
              </div>
            </Card>
          ))}
      </div>
    </Card>
  );
};

export default ESGCards;
