import { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Segmented, Spin } from "antd";

import MapMarkers from "./functions/GetMapMarkers";

import { fetchMapContext } from "../api/api";
import { LoadingOutlined } from "@ant-design/icons";

import "./AuraMap.css";
import MapErrorPopover from "../../pages/MapErrorPopover";
import { Recycling, ScienceOutlined, YardOutlined } from "@mui/icons-material";
import AuraMapGraph from "./AuraMapGraph";

// Fix default icon issues with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Legend = ({ imageUrl }) => {
  const map = useMap();

  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML = `<img src=${imageUrl} alt="Legenda" style="width: 36px;">`;
      return div;
    };

    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map, imageUrl]);

  return null;
};

function AuraMap({ id, coords, graphData, ndviGraphData, classificationRecords }) {
  const [selectedOption, setSelectedOption] = useState("om");
  const [mapColors, setMapColors] = useState([0, 0]);
  const [mapData, setMapData] = useState([]);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [loading, setLoading] = useState(false);
  const [mapKey, setMapKey] = useState(0);
  const [retry, setRetry] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    setMapCenter([coords[0], coords[1]]);

    if (retry == 0) handleSelectorChange("om");

    if (retry >= 1 && retry <= 3 && error == true) {
      const timeout = setTimeout(async () => {
        try {
          await handleSelectorChange(selectedOption);
        } catch (error) {
          console.error("Error in handleSelectorChange:", error);
        }
      }, 20000);

      return () => clearTimeout(timeout);
    }
  }, [retry, error]);

  const handleSelectorChange = async (optionValue) => {
    let hasError = false;

    if (optionValue != selectedOption) setRetry(1);

    setLoading(true);
    setSelectedOption(optionValue);

    try {
      const processedData = await fetchMapContext(optionValue, id);

      const updatedFeatures = processedData.geojson_data.features.map((feature) => {
        if (feature.properties.elevation === 0) {
          feature.properties.elevation = 0.000001;
        }
        return feature;
      });

      setMapData(processedData.geojson_data.features);
      setMapColors(processedData.color_map);
      setMapCenter([processedData.map_center[1], processedData.map_center[0]]);
      setMapKey((prevKey) => prevKey + 1);

      setError(false);
    } catch (error) {
      hasError = true;
      setRetry((retry) => retry + 1);
      setError(true);
      console.error("Failed to fetch map context:", error);
    } finally {
      if (!hasError) setLoading(false);
    }
  };

  const legendPathOption = {
    ndvi: "/assets/mapLegends/Plant-Health.svg",
    om: "/assets/mapLegends/OrgMatter.svg",
    ndre: "/assets/mapLegends/Nitrogen.svg",
  };

  const buttonOptions = [
    {
      value: "ndvi",
      label: "Saúde das plantas",
      icon: <Recycling style={{ fontSize: 16, color: "#434449" }}/>
    },
    {
      value: "om",
      label: "Matéria orgânica",
      icon: <YardOutlined style={{ fontSize: 16, color: "#434449" }}/>
    },
    {
      value: "ndre",
      label: "Nitrogênio",
      icon: <ScienceOutlined style={{ fontSize: 16, color: "#434449" }}/>
    },
  ];

  return (
    <div className="auraMapContainer">
      <Segmented
        options={buttonOptions.map(option => ({
          label: (
            <div className="auraMapButtonLabel">
              {option.icon}
              {option.label}
            </div>
          ),
          value: option.value,
        }))}
        onChange={handleSelectorChange}
        block
        value={selectedOption}
      />
      <div className="auraMapVis">
        {loading ? (
          <div className="auraMapLoading"
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    color: "#B64CFF",
                    fontSize: "40px",
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <MapContainer
            key={mapKey}
            center={mapCenter}
            zoom={15}
            maxZoom={16}
            minZoom={14}
            zoomControl={false}
            style={{ height: "100%", width: "100%", borderRadius: "4px" }}
          >
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            {mapData.length ? (
              <div>
                <MapMarkers
                  option={selectedOption}
                  data={mapData}
                  colors={mapColors}
                />
              <Legend imageUrl={legendPathOption[selectedOption]} /> 
              </div>
            ) 
              : (<div></div>)}
          </MapContainer>
        )}

        {error && retry > 3 && <MapErrorPopover />}
      </div>
      <div className="auraMapGraphContainer">
        <AuraMapGraph
          selectedChart={selectedOption}
          data={graphData}
          ndviData={ndviGraphData}
          classificationRecords={classificationRecords}
        />
      </div>
    </div>
  );
}

export default AuraMap;
