import React from 'react';
import "./VisionEsgChart.css";
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const esgMapping = {
    1: "Área de Proteção Permanente",
    // 2: "AREA_ALTITUDE_SUPERIOR_1800",
    // 3: "AREA_CONSOLIDADA",
    // 4: "AREA_DECLIVIDADE_MAIOR_45",
    // 5: "AREA_IMOVEL",
    // 6: "AREA_POUSIO",
    // 7: "AREA_TOPO_MORRO",
    8: "Banhado",
    // 9: "BORDA_CHAPADA",
    // 10: "HIDROGRAFIA",
    11: "Manguezal",
    // 12: "NASCENTE_OLHO_DAGUA",
    13: "Reserva legal",
    // 14: "Restinga",
    // 15: "Servidão administrativa",
    16: "Uso restrito",
    17: "Vegetação nativa",
    // 18: "Vereda",
    19: "Desmatamento PRODES",
    20: "Embargo ICMBIO",
    21: "Embargo IBAMA",
    22: "Assentamento",
    23: "Área indígena",
    // 42: "Trabalho infantil",
    // 43: "Trabalho escravo",
    24: "Sitio Arqueológico",
    99: "Sitio Arqueológico",
  };

const VisionEsgChart = ({ sicarOverlaps }) => {
    const data = Object.fromEntries(
        Object.entries(esgMapping).map(([id, name]) => [
          name,
          Array.isArray(sicarOverlaps) && sicarOverlaps.some(
            (item) =>
              parseInt(item.id_esg) === parseInt(id) && item.intersect_status
          ),
        ])
    );

    return (
        <div className="visionEsgChartCard">
            <div className='visionEsgChartTitle'>Verificação ESG do talhão</div>
            <div className='visionEsgChartContent'>
                {Object.entries(data).map(([key, value]) => (
                    <div className="visionEsgCard" key={key}>
                        {value 
                            ? <CloseCircleFilled style={{color: "#F5222D", fontSize: 20}} />
                            : <CheckCircleFilled style={{color: "#52C41A", fontSize: 20}} />
                        }
                        <div>{key}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VisionEsgChart;
