import dayjs from 'dayjs';

const rainfallAlertDict = {
  "DAILY_HIGH": "Chuva acima do ideal",
  "DAILY_VERY_HIGH": "Chuva intensa",
  "THREE_DAY_HIGH": "Chuva acima do ideal por mais de 3 dias",
  "THREE_DAY_VERY_HIGH": "Chuva intensa por mais de 3 dias",
  "FIVE_DAY_HIGH": "Chuva acima do ideal por mais de 5 dias",
  "FIVE_DAY_VERY_HIGH": "Chuva intensa por mais de 5 dias",
}; 

function getLastDay(notificationType, startDate) {
  let daysToAdd = 0;

  switch (notificationType) {
    case "THREE_DAY_HIGH":
    case "THREE_DAY_VERY_HIGH":
      daysToAdd = 2;
      break;
    case "FIVE_DAY_HIGH":
    case "FIVE_DAY_VERY_HIGH":
      daysToAdd = 4;
      break;
    case "DAILY_HIGH":
    case "DAILY_VERY_HIGH":
    default:
      daysToAdd = 0;
  }

  return dayjs(startDate).add(daysToAdd, 'day').format("DD/MM/YYYY");
}

function generateAlerts(rainfallNotification, esgAlerts) {
  const alerts = [];
  let alertId = 1;

  if (Array.isArray(rainfallNotification)) {
    rainfallNotification

    rainfallNotification.forEach((item) => {
      alerts.push({
        id: alertId++,
        type: rainfallAlertDict[item.notification_type],
        date: getLastDay(item.notification_type, item.notification_date),
      });
    });
  }

  alerts.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

  if (esgAlerts && Array.isArray(esgAlerts)) {
    esgAlerts.forEach((alert) => {
      if (alert.id_esg !== 3 && alert.id_esg !== 5) {
        alerts.push({
          id: alertId++,
          type: "Alerta ESG",
          id_esg: alert.id_esg,
          date: dayjs(alert.date).format("DD/MM/YYYY"),
        });
      }
    });
  }

  return alerts.reverse();
}

export default generateAlerts;
