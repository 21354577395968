import React from 'react';

import "./EmbargoedCarChart.css";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const EmbargoedCarChart = ({data}) => {

    let label = "Sem áreas próximas";
    let color = "#52C41A";

    if (data.nearby) {
        label = "Área próxima identificada"
        color = "#FAAD14"
    }

    if (data.overlap > 0) {
        label = "Área sobreposta"
        color = "#FF4D4F"
    }
    
    return (
        <div
            className="embargoedCarChartCard"
        >
            <div className='embargoedCarChartTitle'>Áreas embargadas</div>
            <div className='embargoedCarChartContent'>
                <div className='visionEmbargoedAreaDescription'>
                    Proximidade de área de embargo IBAMA
                </div>
                <div className="visionEmbargoedAreaStatus" style={{color: color}}>
                    {label}
                </div>
                <div className="visionEmbargoedAreaDescription">
                    {data.overlap > 0 &&
                        <div>
                            {`Área sobreposta: ${data.overlap} Ha`}
                        </div>
                    }
                    Verificamos um raio de 5km do centro da Gleba
                </div>
            </div>
        </div>
    );
};

export default EmbargoedCarChart;
