import React from "react";
import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import "./Breadcrumbs.css"

const breadcrumbRoutes = {
  "/home": "Home",
  "/kml-upload": "Home / Nova Consulta",
  "/apple": "Home / Apple",
  "/aura": "Home / Aura",
  "/aura/:id": "Home / Aura / Ticket :id",
  "/map": "Home / Map",
  "/sense": "Home / Sense",
  "/sense-basic": "Home / Sense",
  "/sense-basic/:id": "Home / Sense Basic / :id",
  "/sense/:id": "Home / Sense / :id",
  "/vision": "Home / Vision",
  "/vision/:id": "Home / Vision / :id",
  "/users/": "Home / Users",
};

function findBreadcrumbPath(pathname) {
  const routeKeys = Object.keys(breadcrumbRoutes);
  for (let i = 0; i < routeKeys.length; i++) {
    const route = routeKeys[i];
    const regex = new RegExp("^" + route.replace(/:\w+/g, "(\\d+)") + "$");
    const match = pathname.match(regex);
    if (match) {
      return breadcrumbRoutes[route].replace(/:\w+/, match[1]);
    }
  }

  return null;
}

function Breadcrumbs() {
  const location = useLocation();

  const breadcrumbPath = findBreadcrumbPath(location.pathname);

  if (!breadcrumbPath) return null;

  const pathParts = breadcrumbPath.split(" / ");

  return (
    <Breadcrumb
      className="breadcrumbs"
      items={pathParts.map((part, index) => {
        const pathSegments = location.pathname.split('/');
        const pathTo = pathSegments.slice(0, index + 1).join('/') || '/';
        const isLast = index === pathParts.length - 1;
        return {
          title: part,
          href: (isLast ? undefined : pathTo)
        };
      })}
    />
  );
}

export default Breadcrumbs;
