import React, { useState, useEffect } from "react";
import { Card, Typography, Skeleton } from "antd";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");

const { Text } = Typography;

const TemperatureChartTwoWeeks = ({ data }) => {
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    if (data) {
      updateChartData(data);
    }
  }, [data]);

  const updateChartData = (data) => {
    const dates = Object.keys(data);
    const series = [
      {
        name: "Min",
        data: dates.map((date) => data[date].min),
      },
      // {
      //   name: "Méd.",
      //   data: dates.map((date) => data[date].med),
      // },
      {
        name: "Max",
        data: dates.map((date) => data[date].max),
      },
    ];
    setChartSeries(series);
  };

  if (!data || Object.keys(data).length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;
  }

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#26A0FC", "#FDBB3A"],
    xaxis: {
      categories: Object.keys(data).map((date) => dayjs(date).format("DD/MM")),
    },
    yaxis: {
      max: 45,
      min: 0,
      tickAmount: 10,
    },
    stroke: {
      show: true,
      width: 0.2,
      colors: ["transparent"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
      },
    },
    grid: {
      show: true,
      borderColor: "#e9e9e9",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
  };

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#B64CFF",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              maxWidth: "calc(100% - 100px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Gráficos de temperatura
          </Text>
        </div>
      }
      bordered={true}
      style={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        flex: 1,
      }}
    >
      <ReactApexChart
        options={options}
        series={chartSeries}
        type="bar"
        height={"320px"}
      />
    </Card>
  );
};

export default TemperatureChartTwoWeeks;
