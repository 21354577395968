import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Divider } from 'antd';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isLeapYear);

import "./VisionUpsellChart.css";

const VisionUpsellChart = ({
    upsellArea,
    upsellAreaPercentage,
    consultedArea,
    consultedAreaUpsellPercentage
}) => {
    const series = [upsellArea, consultedArea];

    const options = {
        labels: ['Upsell', 'Área consultada'],
        colors: ['#B64CFF', '#1890FF'],
        legend: {
            show: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                    labels: {
                        show: false,
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        }
    };

    return (
        <div className="visionUpsellCard">
            <div className='visionUpsellChartTitle'>
                Potencial de venda
            </div>
            <div className="visionUpsellChartContent">
                <div className='visionUpsellChartContainer'>
                    <ReactApexChart
                        className="visionUpsellChart"
                        options={options}
                        series={series}
                        type="donut"
                    />
                </div>
                <div className='visionUpsellLegend'>
                    <div className='visionUpsellLegendLine'>
                        <div className='visionUpsellLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#B64CFF",
                                }}
                            ></div>
                            Upsell
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(upsellArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    <div className='visionUpsellLegendLine'>
                        <div className='visionUpsellLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#1890FF",
                                }}
                            ></div>
                            Área consultada
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(consultedArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisionUpsellChart;
