import React from "react";

function TopFrameLogin() {
  return (
    <div className="TopFrameLogin">
      <img
        src="/assets/logo-01-white.svg" // Path to your image
        alt="Agromai logo"
        width="400"
        height="72"
        style={{ marginTop: "8px" }}
      />
    </div>
  );
}

export default TopFrameLogin;
