function dataToRainfallNDVI(data, ndviData) {
    const safraData = [];
  
    data.forEach((yearData, index) => {

      // console.log('year data: ', yearData, 'index: ', index)
      // const startSafraIndex = yearData.data.findIndex(
      //   (monthData) => new Date(monthData.date).getMonth() === 5
      // );
      const startSafraIndex = 5;
  
      if (index < data.length - 1) {
        let nextYearData = data[index + 1].data;
        let endSafraIndex = nextYearData.findIndex(
          (monthData) => new Date(monthData.date).getMonth() === 5
        );

        if (endSafraIndex === -1) {
          endSafraIndex = 5;
        }
  
        const safra = {
          safraLabel:
            'Safra ' +
            (parseInt(yearData.year) + 1).toString().substr(2) +
            '/' +
            yearData.year.toString().substr(2),
          data: [
            ...yearData.data.slice(startSafraIndex),
            ...nextYearData.slice(0, endSafraIndex + 1),
          ],
        };

        safraData.push(safra);
      }
    });
  
    const ndviLookup = new Map();
    if (ndviData) {
      ndviData.forEach((ndvi) => {

        const key = String(ndvi.year) + '-' + String(ndvi.month).padStart(2, '0');

        ndviLookup.set(key, ndvi.avg_value);
      });
    }
  
    safraData.forEach((safra) => {
      safra.data.forEach((dateEntry) => {
        const [yearStr, monthStr] = dateEntry.date.split('-');
        const year = Number(yearStr);
        const month = Number(monthStr);

        const formattedMonth = String(month).padStart(2, '0');

        
        // const date = new Date(dateEntry.date);
        // const year = date.getFullYear();
        // const month = date.getMonth() + 1;

        const ndviKey = `${year}-${formattedMonth}`;
        // const ndviKey = String(year) + '-' + String(month).padStart(2, '0');

  
        if (ndviLookup.has(ndviKey)) {
          dateEntry.ndvi = ndviLookup.get(ndviKey);
        }
      });
    });
  
    safraData.reverse();
  
    return safraData;
  }
  
export default dataToRainfallNDVI
  