import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Users.css";
import {
  Divider,
  Select,
  Input,
  Button,
  Card,
  Col,
  Row,
  Avatar,
  Flex,
  Typography,
  Modal,
  Form,
  Space,
  message,
  Tooltip,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  RedoOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  createUser,
  deleteUser,
  fetchUsers,
  updateUser,
} from "../components/api/api";
import Cookies from "js-cookie";
import Unauthorized from "./Unauthorized";

const { Search } = Input;
const { Text } = Typography;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const UsersList = () => {
  const [page, setPage] = useState(1);
  const [unauthorized, setUnauthorized] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);

  const [displayStatus, setDisplayStatus] = useState([]);
  const [displayOrder, setDisplayOrder] = useState("A");
  const [searchTerm, setSearchTerm] = useState("");
  const [modalAddUser, setModalAddUser] = useState(false);
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [isPasswordGenerated, setIsPasswordGenerated] = useState(false);

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditFormValid, setIsEditFormValid] = useState(false);
  const [loggedInUserId, setLoggedInUserId]  = useState(Cookies.get("user-id"));
  const [isRole, setIsRole] = useState(null);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let newPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setPassword(newPassword);
    setIsPasswordGenerated(true);

    handleFormChange();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password);
    message.success("Senha copiada para a área de transferência!");
  };

  const handleSubmit = async () => {
    form.submit();
  };

  useEffect(() => {
    const role = Cookies.get("role");
    if (role === "DI") {
      setUnauthorized(false);
    } else {
      setUnauthorized(true);
    }
  }, []);

  const loadUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchUsers(page);
      setUsers(data.results);
      setCount(data.count);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [page]);

  const handleCancel = () => {
    form.resetFields();
    setModalAddUser(false);
  };

  const onFinish = async (values) => {
    if (isPasswordGenerated) {
      handleCopyToClipboard();
    }

    try {
      const response = await createUser({
        name: values.name,
        email: values.email,
        password: password,
        role: "SW",
        is_registered: true,
      });

      form.resetFields();
      const newUser = {
        id: response.user.id,
        name: response.user.name,
        email: response.user.email,
        created_at: new Date().toLocaleDateString(),
      };
      setUsers([...users, newUser]);
      message.success("Usuário adicionado com sucesso!");

      setModalAddUser(false);
    } catch (error) {
      // console.error("Erro ao criar usuário:", error);
    }
  };

  const handleRefresh = useCallback(async () => {
    await loadUsers();
  }, [loadUsers]);

  const handleChangeStatus = (selectedOptions) => {
    setDisplayStatus(selectedOptions || []);
  };

  const handleChangeOrder = (selectedOption) => {
    setDisplayOrder(selectedOption);
  };

  const handleDelete = (user_id) => async (event) => {
    event.stopPropagation();
    try {
      await deleteUser(user_id);

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      // console.error("Error deleting ticket:", error);
    }
  };

  const renderUserCard = (user) => (
    <Col span={12} key={user.id}>
      <Card style={{ height: "112px" }}>
        <Flex>
          <Avatar
            size={60}
            style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
          >
            {user.name ? user.name.charAt(0) : "U"}
          </Avatar>
          <Flex
            vertical
            align="flex-start"
            style={{ marginLeft: "25px", padding: 0, width: "100%" }}
          >
            <Flex
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <strong>{user.name || "Nome não informado"}</strong>
                <div>email: {user.email}</div>
                <div>Adicionado em: {formatDate(user.created_at)}</div>
              </div>
              <Flex
                style={{
                  marginLeft: "auto",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  type="link"
                  style={{ color: "rgba(182, 76, 255, 1)" }}
                  onClick={() => showEditModal(user)}
                >
                  <EditOutlined /> Editar
                </Button>

                {user.id != loggedInUserId && ( // Renderiza o botão apenas se o usuário não for o logado
                  <div style={{ marginTop: "10px" }}>
                    <Tooltip title="Excluir">
                      <Button
                        className="deleteTicketButton"
                        type="ghost"
                        onClick={() => handleDelete(user.id)}
                        style={{ color: "red" }}
                      >
                        <DeleteOutlined /> Remover
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Col>
  );

  useEffect(() => {
    form.setFieldsValue({ isPasswordGenerated });
    handleFormChange();
  }, [isPasswordGenerated]);

  const handleFormChange = () => {
    const fields = form.getFieldsValue();
    const requiredFields = ["name", "email"];
    const allFilled = requiredFields.every(
      (field) => fields[field] && !form.getFieldError(field).length
    );

    setIsFormValid(allFilled && isPasswordGenerated);
  };

  const handleEditFormChange = (_, allFields) => {
    const allFilled = allFields.every(
      (field) => field.errors.length === 0 && field.value
    );
    const password = form.getFieldValue("password");
    const confirmPassword = form.getFieldValue("confirmPassword");
    const passwordsMatch = password === confirmPassword;
    setIsEditFormValid(allFilled && passwordsMatch);
  };

  // modal edit
  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Por favor, insira a senha do usuário"));
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        new Error(
          "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
        )
      );
    }
    return Promise.resolve();
  };

  const showEditModal = (user) => {

    if(user.id == loggedInUserId) setIsRole('DI')
    setCurrentUser(user);
    setIsModalEditVisible(true);
    form.setFieldsValue({
      name: user.name,
      email: user.email,
    });

    setUserId(user.id);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      setIsModalEditVisible(false);

      const response = await updateUser({
        id: userId,
        name: values.name,
        email: values.email,
        password: values.password,
        role: isRole ? isRole : "SW",
        is_registered: true,
      });

      form.resetFields();

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, ...response } : user
        )
      );
    } catch (error) {
      // console.error("Erro ao editar usuário:", error);
    }
  };

  const handleEditCancel = () => {
    form.resetFields();

    setIsModalEditVisible(false);
  };

  if (unauthorized) {
    return <Unauthorized />;
  }

  const filteredTickets = useMemo(() => {
    let filtered = users || [];

    if (searchTerm.trim()) {
      filtered = filtered.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    switch (displayOrder) {
      case "A": // A-Z
        return filtered.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
      case "B": // Z-A
        return filtered.sort((a, b) =>
          b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        );
      case "C": // Mais recente
        return filtered.sort((a, b) =>
          new Date(b.created_at) - new Date(a.created_at)
        );
      case "D": // Mais antigo
        return filtered.sort((a, b) =>
          new Date(a.created_at) - new Date(b.created_at)
        );
      default:
        return filtered;
    }
  }, [users, searchTerm, displayOrder]);

  return (
    <div className="usersContainer">
      <div>
        <h1 className="usersTitle">Gerenciamento de Equipes</h1>
        <Divider className="customDivider" />
      </div>

      <div className="ticketListingBar">
        <RedoOutlined
          className="refreshTicketListing"
          onClick={handleRefresh}
        />

        <Button
          onClick={() => setModalAddUser(true)}
          icon={<UserAddOutlined />}
          className="buttonCustom"
        >
          Adicionar usuário
        </Button>

        <Search
          onSearch={setSearchTerm}
          allowClear
          size="large"
          placeholder="Buscar usuário"
        />

        <div className="selectorBox">
          Ordem de exibição:
          <Select
            style={{ minWidth: "184px", height: "48px" }}
            placeholder="Selecione"
            defaultValue={displayOrder}
            onChange={handleChangeOrder}
            options={[
              { value: "A", label: "Ordem A-Z" },
              { value: "B", label: "Ordem Z-A" },
              { value: "C", label: "Adição mais recente" },
              { value: "D", label: "Adição mais antiga" },
            ]}
          />
        </div>
      </div>

      <div>
        <Row gutter={[24, 24]}>{filteredTickets.map(renderUserCard)}</Row>
        <Modal
          title={
            <div>
              Adicionar novo usuário
              <Divider style={{ margin: "8px 0" }} />
            </div>
          }
          centered
          visible={modalAddUser}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              disabled={!isFormValid}
              className="buttonCustom"
            >
              Criar Usuário
            </Button>,
          ]}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Form
            onFieldsChange={handleFormChange}
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              label="Nome do novo usuário"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o nome do usuário",
                },
              ]}
            >
              <Input
                placeholder="João Pedro da Silva"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail de acesso"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o e-mail do usuário",
                },
                { type: "email", message: "Formato de e-mail inválido" },
              ]}
            >
              <Input
                placeholder="exemplo@email.com"
                prefix={<MailOutlined />}
              />
            </Form.Item>

            <Form.Item name="password" label="Gerar senha de acesso:">
              <Button
                key="submit"
                type="primary"
                onClick={() => generatePassword()}
                className="buttonCustom"
              >
                Gerar
              </Button>
              {isPasswordGenerated && (
                <>
                  <span style={{ marginLeft: 10 }}>Senha gerada</span>
                  <CopyOutlined
                    style={{ marginLeft: 5, cursor: "pointer" }}
                    onClick={handleCopyToClipboard}
                  />
                </>
              )}{" "}
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Editar Usuário"
          visible={isModalEditVisible}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleEditOk}
              disabled={!isEditFormValid}
              className="buttonCustom"
            >
              Editar Usuário
            </Button>,
          ]}
          onCancel={handleEditCancel}
        >
          <Form
            form={form}
            layout="vertical"
            name="editUserForm"
            onFieldsChange={handleEditFormChange}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o nome do usuário",
                },
              ]}
            >
              <Input
                placeholder="João Pedro da Silva"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o e-mail do usuário",
                },
                { type: "email", message: "Formato de e-mail inválido" },
              ]}
            >
              <Input
                placeholder="exemplo@email.com"
                disabled
                prefix={<MailOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Senha"
              rules={[
                {
                  required: true,
                  validator: validatePassword,
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Digite a senha" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirme a senha"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor, confirme a senha",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("As senhas não correspondem!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirme a senha" />
            </Form.Item>
            <Text type="secondary">
              A senha deve conter:
              <ul>
                <li>Pelo menos 8 caracteres;</li>
                <li>Pelo menos uma caractere maiúscula;</li>
                <li>Pelo menos uma caractere minúscula;</li>
                <li>Pelo menos um número de 0 a 9;</li>
                <li>Pelo menos uma caractere especial.</li>
              </ul>
            </Text>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default UsersList;
