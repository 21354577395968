import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Result,
  Select,
  Upload,
  message,
  Divider,
} from "antd";
import {
  FileAddOutlined,
  FileImageOutlined,
  PaperClipOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import StepTracker from "../components/dashboard/StepTracker";
import { bulkSubmitAnalysis } from "../components/api/api";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import "./KmlUpload.css";

function KmlUpload() {
  const errorShownRef = useRef(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [formFileList, setFormFileList] = useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    producerName: "",
    farmName: "",
    cpf: "",
  });

  const navigate = useNavigate();

  const nextStep = () => {
    if (currentStep === 2 && formFileList.length > 0) {
      console.log("submitting for analysis");
      handleSubmitForAnalysis();
      sessionStorage.clear();
      localStorage.clear();
      console.log("submitted for analysis and cache cleared");
    } else {
      console.error(errorShownRef.current);
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => setCurrentStep(currentStep - 1);

  const renderItem = (originNode, file, fileList, actions) => {
    const formDataIndex = fileList.indexOf(file);
    const formData = formDataList[formDataIndex] || {};

    return (
      <div className="kmlFileRender" key={file.uid}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <FileImageOutlined style={{ fontSize: "32px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div className="fieldNameLabel">Nome do talhão:</div>
              <Form.Item className="fieldNameForm" required>
                <Input
                  type="text"
                  className="fieldNameInput"
                  name="fieldName"
                  placeholder="Talhão 01"
                  value={formData.fieldName || ""}
                  onChange={(e) => handleFormDataChange(e, formDataIndex)}
                  required
                />
              </Form.Item>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Form.Item required style={{ marginBottom: "0px" }}>
                <Select
                  className="cropTypeSelector"
                  onChange={(value) =>
                    handleSelectChange(value, "cropType", formDataIndex)
                  }
                  value={formData.cropType || null}
                  size="small"
                  placeholder="Cultura"
                  disabled={["vision"].includes(formData.serviceType)}
                >
                  <Select.Option value="soja">Soja</Select.Option>
                  <Select.Option value="trigo">Trigo</Select.Option>
                  <Select.Option value="milho">Milho</Select.Option>
                  <Select.Option value="arroz" disabled>Arroz</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item required style={{ marginBottom: "0px" }}>
                <Select
                  className="soilTypeSelector"
                  onChange={(value) =>
                    handleSelectChange(value, "soilType", formDataIndex)
                  }
                  value={formData.soilType || null}
                  size="small"
                  placeholder="Tipo do solo"
                  disabled={["vision"].includes(formData.serviceType)}
                >
                  <Select.Option value="1"> Solo AD 1 </Select.Option>
                  <Select.Option value="2"> Solo AD 2 </Select.Option>
                  <Select.Option value="3"> Solo AD 3 </Select.Option>
                  <Select.Option value="4"> Solo AD 4 </Select.Option>
                  <Select.Option value="5"> Solo AD 5 </Select.Option>
                  <Select.Option value="6"> Solo AD 6 </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item required style={{ marginBottom: "0px" }}>
                <Select
                  className="cycleTypeSelector"
                  onChange={(value) =>
                    handleSelectChange(value, "cycleType", formDataIndex)
                  }
                  value={formData.cycleType || null}
                  size="small"
                  placeholder="Tipo de ciclo"
                  disabled={formData.cropType === "arroz" ||
                    ["vision", "sense"].includes(formData.serviceType)}
                >
                  <Select.Option value="1">Precoce</Select.Option>
                  <Select.Option value="2">Longo</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item required style={{ marginBottom: "0px" }}>
                <Input
                  type="text"
                  className="proposalIdInput"
                  name="proposalId"
                  value={formData.proposalId || ""}
                  onChange={(e) => handleFormDataChange(e, formDataIndex)}
                  size="small"
                  placeholder="ID da Proposta"
                  required
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Form.Item required style={{ marginBottom: "0px" }}>
              <Select
                className="serviceSelector"
                onChange={(value) =>
                  handleSelectChange(value, "serviceType", formDataIndex)
                }
                value={formData.serviceType || null}
                size="small"
                placeholder="Serviço"
              >
                {!["arroz", "trigo"].includes(formData.cropType) && (
                  <Select.Option value="aura">Aura</Select.Option>
                )}
                <Select.Option value="sense">Sense</Select.Option>
                <Select.Option value="vision">Vision</Select.Option>
              </Select>
            </Form.Item>
            <Button
              className="removeFileButton"
              type="link"
              onClick={() => handleRemoveFile(file)}
            >
              Remover
            </Button>
          </div>
          <div className="uploadFileName">
            <PaperClipOutlined />
            {file.name}
          </div>
        </div>
      </div>
    );
  };

  const handleRemoveFile = (file) => {
    const updatedFileList = formFileList.filter((f) => f.uid !== file.uid);
    const updatedFormDataList = formDataList.filter(
      (_, index) => formFileList.indexOf(file) !== index
    );

    if (updatedFormDataList.length < 20) {
      errorShownRef.current = false;
    }

    setFormFileList(updatedFileList);
    setFormDataList(updatedFormDataList);
  };

  const isUploadButtonDisabled = () => {
    if (!formFileList.length) {
      return true;
    }
  
    return formDataList.some((formData) => {
      const { fieldName, cropType, soilType, cycleType, proposalId, serviceType } = formData;
  
      if (serviceType === "aura") {
        return !fieldName || !cropType || !soilType || !cycleType || !proposalId;
      }
  
      if (serviceType === "sense") {
        return !fieldName || !cropType || !soilType || !proposalId;
      }
  
      if (serviceType === "vision") {
        return !fieldName || !proposalId;
      }
  
      return true;
    });
  };

  const handleSubmitForAnalysis = async () => {
    setLoading(true);
    try {
      const submitData = formDataList.map((formData, index) => ({
        ...generalInfo,
        ...formData,
        file: formFileList[index] ? formFileList[index].originFileObj : null,
      }));
      await Promise.resolve(bulkSubmitAnalysis(submitData));
      setCurrentStep(3);
    } catch (error) {
      console.error("Error submitting for analysis:", error.responseData);
      if (error.code === 402) {
        // let credits =  error.responseData.detail.match(/(\d+)\s+credits/);
        message.error(`O limite de créditos foi atingido`);
      } else {
        if (error.responseData["invalid_kmls"]) {
          message.error({
            content: `Falha ao enviar para análise. Arquivos inválidos: ${error.responseData["invalid_kmls"]?.join(', ')}`,
            duration: 5,
          })
        }
        else {
          message.error(
            `Algo deu errado ao enviar para análise`
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormDataList([]);
    setFormFileList([]);
    setGeneralInfo({
      producerName: "",
      farmName: "",
      cpf: "",
    });
    setCurrentStep(1);
  };

  const handleFormDataChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [name]: value,
    };
    setFormDataList(updatedFormDataList);
  };

  const handleSelectChange = (value, option, index) => {
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [option]: value,
    };

    if (option === "cropType" && value === "arroz") {
      updatedFormDataList[index].cycleType = "2";
      if (updatedFormDataList[index].serviceType === "aura") {
        updatedFormDataList[index].serviceType = "";
      }
    }

    if (option === "cropType" && value === "trigo") {
      if (updatedFormDataList[index].serviceType === "aura") {
        updatedFormDataList[index].serviceType = "";
      }
    }

    if (option === "serviceType" && value === "vision") {
      updatedFormDataList[index].cropType = "";
      updatedFormDataList[index].soilType = "";
      updatedFormDataList[index].cycleType = "";
    }

    if (option === "serviceType" && value === "sense") {
      updatedFormDataList[index].cycleType = "";
    }

    setFormDataList(updatedFormDataList);
  };

  const onFileChange = ({ fileList }) => {
    const exceededFiles = fileList.slice(20);
    const exceededFileNames = exceededFiles.map((file) => file.name).join(", ");

    if (fileList.length > 20 && !errorShownRef.current) {
      message.error({
        content: `Você pode fazer upload de no máximo 20 arquivos. Arquivos removidos: ${exceededFileNames} `,
        duration: 4,
      });
      errorShownRef.current = true;
    }

    if (fileList.length < 20) {
      errorShownRef.current = false;
    }

    fileList = fileList.slice(0, 20);

    const updatedFormDataList = [...formDataList];

    fileList.forEach((file, index) => {
      if (!updatedFormDataList[index]) {
        updatedFormDataList[index] = {
          fieldName: "",
          cropType: "",
          soilType: "",
          cycleType: "",
          proposalId: "",
          serviceType: "",
        };
      }
    });

    setFormDataList(updatedFormDataList);
    setFormFileList(fileList);
  };

  const beforeUpload = () => false;

  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const csvData = results.data.reduce((acc, row) => {
          const fileName = row.fileName && row.fileName.trim().toLowerCase();
          if (fileName) {
            acc[fileName] = {
              fieldName: row.fieldName,
              cropType: mapCropType(row.cropType),
              cycleType: mapCycleType(row.cycleType),
              soilType: mapSoilType(row.soilType),
              proposalId: row.proposalId,
              serviceType: row.service.toLowerCase(),
            };
          }
          return acc;
        }, {});

        setFormDataList((prevFormDataList) =>
          formFileList.map((file, index) => {
            const trimmedFileName = file.name.trim().toLowerCase();
            return {
              ...prevFormDataList[index],
              ...(csvData[trimmedFileName] || {}),
            };
          })
        );
        message.success("CSV carregado com sucesso");
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        message.error("Erro ao carregar o CSV");
      },
    });

    return false;
  };

  const mapCropType = (crop) => {
    const validCrops = ["soja", "trigo", "milho", "arroz", "laudo"];
    const lowerCaseCrop = crop.toLowerCase();
    return validCrops.includes(lowerCaseCrop) ? lowerCaseCrop : null;
  };

  const mapCycleType = (cycle) => {
    const cycleMap = {
      precoce: "1",
      longo: "2",
      1: "1",
      2: "2",
    };

    return cycleMap[cycle.toLowerCase()] || null;
  };

  const mapSoilType = (soil) => {
    const soilMap = {
      "solo tipo 1": "1",
      "solo tipo 2": "2",
      "solo tipo 3": "3",
      "solo ad 1": "1",
      "solo ad 2": "2",
      "solo ad 3": "3",
      "solo ad 4": "4",
      "solo ad 5": "5",
      "solo ad 6": "6",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
    };

    return soilMap[soil.toLowerCase()] || null;
  };

  return (
    <div className="kmlUploadForm">
      <div className="kmlUploadHeader">
        <p>Nova consulta</p>
        <Divider className="customDivider" />
      </div>
      <div className="kmlUploadCard">
        <StepTracker currentStep={currentStep} />
        <Form layout="vertical" className="formContainer">
          {currentStep === 1 && (
            <div>
              <Form.Item required>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "32px",
                  }}
                >
                  <div className="kmlUploadFormTitle">ID do cliente:</div>
                  <Input
                    type="text"
                    name="producerName"
                    placeholder="AA123456"
                    value={generalInfo.producerName}
                    onChange={(e) =>
                      setGeneralInfo({
                        ...generalInfo,
                        producerName: e.target.value,
                      })
                    }
                    style={{ width: "290px", height: "44px" }}
                    required
                  />
                </div>
              </Form.Item>
              <Form.Item required>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "32px",
                  }}
                >
                  <div className="kmlUploadFormTitle">Nome da fazenda:</div>
                  <Input
                    type="text"
                    name="farmName"
                    placeholder="Fazenda Pessegueiro"
                    value={generalInfo.farmName}
                    onChange={(e) =>
                      setGeneralInfo({
                        ...generalInfo,
                        farmName: e.target.value,
                      })
                    }
                    style={{ width: "290px", height: "44px" }}
                    required
                  />
                </div>
              </Form.Item>
              <Form.Item required>
                <div
                  className="formButtons"
                  style={{
                    justifyContent: "right",
                    marginTop: "80px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={nextStep}
                    className="buttonCustom"
                    loading={loading}
                    disabled={
                      !generalInfo.producerName || !generalInfo.farmName
                    }
                  >
                    Continuar
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
          {currentStep === 2 && (
            <div style={{ width: "100%" }}>
              <div className="kmlUploadContent">
                *Atenção! Limite máximo de 20 arquivos por upload.
              </div>
              <Form.Item required>
                <Upload
                  fileList={formFileList}
                  onChange={onFileChange}
                  beforeUpload={beforeUpload}
                  accept=".kml, application/vnd.google-earth.kml+xml"
                  multiple
                  showUploadList={false}
                >
                  <Button
                    icon={<FileAddOutlined />}
                    disabled={formFileList.length >= 20}
                    className="buttonCustom"
                    style={{ marginRight: 16 }}
                  >
                    Adicionar arquivos .kml
                  </Button>
                </Upload>
                <Upload
                  accept=".csv"
                  beforeUpload={handleCSVUpload}
                  showUploadList={false}
                >
                  <Button
                    className="buttonCustomWhite"
                    icon={<UploadOutlined />}
                    style={{ marginRight: "8px" }}
                    key="newBulkUpload"
                    disabled={
                      !generalInfo.producerName || !generalInfo.farmName
                    }
                  >
                    Envio de arquivo .csv
                  </Button>
                </Upload>
              </Form.Item>
              <div className="fileListContainer">
                {formFileList.map((file, index) =>
                  renderItem(null, file, formFileList, null)
                )}
              </div>
              <Form.Item required>
                <div className="formButtons">
                  <Button
                    onClick={prevStep}
                    className="buttonCustomWhite"
                    style={{ marginRight: "8px" }}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="primary"
                    onClick={nextStep}
                    className="buttonCustom"
                    loading={loading}
                    icon={<SendOutlined />}
                    disabled={isUploadButtonDisabled()}
                  >
                    Enviar para Análise
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
          {currentStep === 3 && (
            <Result
              status="success"
              title="Upload realizado com sucesso"
              extra={[
                <Button
                  className="buttonCustomWhite"
                  onClick={resetForm}
                  key="newForm"
                >
                  Nova consulta
                </Button>,
                <Button
                  className="buttonCustom"
                  onClick={() => {
                    const senseCount = formDataList.filter(
                      (formData) => formData.serviceType === "sense"
                    ).length;
                    const auraCount = formDataList.filter(
                      (formData) => formData.serviceType === "aura"
                    ).length;
                    const visionCount = formDataList.filter(
                      (formData) => formData.serviceType === "vision"
                    ).length;

                    const maxCount = Math.max(
                      senseCount,
                      auraCount,
                      visionCount
                    );

                    let destination = "/aura";
                    if (senseCount === maxCount) destination = "/sense-basic";
                    if (visionCount === maxCount) destination = "/vision";

                    navigate(destination);
                  }}
                  key="seeHistory"
                >
                  Ver no histórico
                </Button>,
              ]}
            />
          )}
        </Form>
      </div>
    </div>
  );
}

export default KmlUpload;
