import React, { useState } from "react";
import { Typography, Popover, Tooltip } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import HelpPopout from "../components/layout/HelpPopout";
import "./VisionErrorPopover.css";

const { Text } = Typography;

const MapErrorPopover = () => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const hasError = true;
  
  if (hasError) {
    return (
      <div className="error-popover-overlay">
        <div className="error-popover-content">
          <div className="error-popover-card">
            <div className="error-popover-card-content">
              <FrownOutlined className="errorIcon" />
              <Text className="errorTitle">
                Não conseguimos concluir esta solicitação agora.
              </Text>
              <Text className="errorDescription">
                Por favor, envie uma solicitação de suporte para que nosso time
                possa verificar esse problema!
              </Text>
              <Popover
                content={<HelpPopout closePopover={() => setOpen(false)} />}
                trigger="click"
                placement="right"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <Tooltip placement="right" key="Ajuda">
                  <button
                    onClick={() => setOpen(!open)}
                    className="error-button"
                  >
                    Reportar um Erro
                  </button>
                </Tooltip>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default MapErrorPopover;
