import React from "react";
import TopFrameLogin from "./TopFrameLogin";

function LayoutLogin({ children }) {
  return (
    <div>
      <TopFrameLogin />
      <div>
        {children}
      </div>
    </div>
  );
}

export default LayoutLogin;
