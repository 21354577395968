import { useMap } from "react-leaflet";
import { useEffect } from "react";
import L from "leaflet";

import getColor from "./GetColor";

function GetMapMarkers({ option, data, colors }) {
  const map = useMap();

  useEffect(() => {
    // console.log(data);
    if (!data || data.length === 0) return;

    data.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry.type !== "Polygon" || !properties.elevation) return;

      const coordinates = geometry.coordinates[0].map((coord) => [
        coord[1],
        coord[0],
      ]);
      const pointColor = getColor(parseFloat(properties.elevation), colors);

      const polygon = L.polygon(coordinates, {
        color: pointColor,
        fillColor: pointColor,
        weight: 1,
        opacity: 0.22,
        fillOpacity: .82,
      }).addTo(map);

      polygon.bindPopup(`<b>Lon:</b> ${properties.lon} <br> <b>Lat:</b> ${properties.lat}`);
    });

    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          map.removeLayer(layer);
        }
      });
    };
  }, [map, option, data]);

  return null;
}

export default GetMapMarkers;
