import React, { useState, useEffect } from "react";
import { Typography, Skeleton } from "antd";
import DashboardHeaderButtons from "./HeaderButtons";
import "./Header.css";

const { Title, Text } = Typography;

const DashboardHeader = ({ ticket, onStatusChange }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(!ticket);
  }, [ticket]);

  return (
    <div className="reportHeaderContainer">
      {isLoading ? (
        <>
          <Skeleton.Input className="title-style" active size="default" />
          <br />
          <Skeleton.Input className="text-style" active size="small" />
        </>
      ) : (
        <div className="reportHeaderContent">
          <div className="reportHeaderTexts">
            <Title level={4} className="reportHeaderTitle">
              {ticket.farm_data.proposal_id} - {ticket.farm_data.field_name}
            </Title>
          </div> 
            <DashboardHeaderButtons 
              ticket={ticket}
              onStatusChange={onStatusChange}
            />
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
