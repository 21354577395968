import React, { useState, useEffect } from "react";
import { loadNotionContent } from "./api/api";
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";

const UserGuideCard = ({ pageId, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notionData, setNotionData] = useState(null);

  useEffect(() => {
    const loadNotionPage = async () => {
      setIsLoading(true);
      try {
        const data = await loadNotionContent(pageId);
        setNotionData(data);
      } catch (err) {
        console.log({ err });
      } finally {
        setIsLoading(false);
      }
    };

    loadNotionPage();
  }, [pageId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div onClick={onClick}>
      {notionData ? (
        <NotionRenderer blockMap={notionData} />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default UserGuideCard;
