import React from "react";
import { fetchSenseTickets } from "../components/api/api";
import TicketListingSense from "../components/TicketListingSense";

function Sense() {  
  return (
    <div>
      <TicketListingSense fetchTickets={fetchSenseTickets}/>
    </div>
  );
}

export default Sense;
