function formatDateSimple(dateString) {
  const [year, month, day] = dateString.split("-");

  const date = new Date(year, month - 1, day); // Month is zero-based

  const paddedDay = date.getDate().toString().padStart(2, "0");
  const paddedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const fullYear = date.getFullYear();

  //console.log(`${dateString} = ${paddedDay}/${paddedMonth}/${fullYear}`)
  return `${paddedDay}/${paddedMonth}/${fullYear}`;
}

export default formatDateSimple;
