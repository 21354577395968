import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import { Divider, Skeleton } from "antd";
import { FlagFilled, ExclamationCircleFilled } from "@ant-design/icons";

import "./Notifications.css";

const esgMapping = {
  1: "APP",
  8: "banhado",
  11: "manguezal",
  13: "reserva legal",
  16: "uso restrito",
  17: "vegetação nativa",
  19: "desmatamento PRODES",
  20: "embargo ICMBIO",
  21: "embargo IBAMA",
  22: "assentamento",
  23: "área indígena",
};

const Notifications = ({ notifications }) => {
  const [data, setData] = useState([]);

  const loadMoreData = () => {
    setData((prevData) => [...prevData, ...notifications.slice(prevData.length, prevData.length + 10)]);
  };

  useEffect(() => {
    if (notifications) {
      loadMoreData();
    }
  }, [notifications]);

  return (
    <div className="notificationsContainer">
      <h2 className="notificationsContainerTitle">Notificações</h2>
      <Divider style={{ marginBottom: "8px" }} />
      <div className={notifications && notifications.length === 0 ? "notificationNoContent" : "notificationContent"}>
        {notifications && notifications.length === 0 ? (
          <div className="notificationsCardNoContent">
            <div className="notificationsCardNoContentTitle">Tudo em ordem!</div>
            Este talhão não teve notificações para eventos atípicos nos últimos quinze dias.
          </div>
        ) : (
          <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={data.length < notifications.length}
            loader={<Skeleton className="flexSkelly" active />}
            scrollableTarget="scrollableDiv"
            className="infinite-scroll-container"
          >
            {data.map((notification) =>
              notification.type !== "Alerta ESG" ? (
                <div className="notificationsCard" key={notification.id}>
                  <ExclamationCircleFilled className="notificationsCardIcon" />
                  <div style={{ height: "100%" }}>
                    <p className="notificationsCardTitle">Alerta de clima atípico</p>
                    <div className="notificationsCardText">{notification.type}</div>
                    <p className="notificationsCardDate">{notification.date}</p>
                  </div>
                </div>
              ) : (
                <div className="notificationsCard" key={notification.id}>
                  <FlagFilled className="esgNotificationsCardIcon" />
                  <div style={{ height: "100%" }}>
                    <p className="notificationsCardTitle">Alerta ESG</p>
                    <div className="notificationsCardText">
                      {`Detectada interseção com área de ${esgMapping[notification.id_esg] || 'ESG'}`}
                    </div>
                    <p className="notificationsCardDate">{notification.date}</p>
                  </div>
                </div>
              )
            )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Notifications;
