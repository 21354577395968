import React from "react";
import TopFrame from "./TopFrame";
import Sidebar from "./Sidebar";
import "./Layout.css";

function LayoutVisionDashboard({ children }) {
    return (
        <>
            <TopFrame />
            <Sidebar />
            <div className="appContainerFull">
                {children}
            </div>
        </>
    );
}

export default LayoutVisionDashboard;
