import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/auth/AuthContext";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Flex,
  Alert,
  Spin,
  Popover,
  Divider
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
  SendOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "../pages/Login.css";

const handleEmail = () => {
  const email = "suporte@agromai.com.br";
  const subject = encodeURIComponent("Esqueci minha senha");

  window.open(`mailto:${email}?subject=${subject}`, '_blank');
};

const content = (
  <div className="forgottenPassPopup">
    <div>
      Em caso de problemas com sua senha,
      entre em contato com o suporte:
      <Divider style={{marginTop: "8px", marginBottom: "8px"}}/>
      <div className="sendEmailButton">
        suporte@agromai.com.br
        <Button
          type="link"
          className="sendEmailButtonIcon"
          icon={<SendOutlined />}
          onClick={handleEmail}
        />
      </div>
    </div>
  </div>
);

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberEmail, setRememberEmail] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setUsername(rememberedEmail);
      setRememberEmail(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const success = await auth.login(username, password);

      if (success) {
        if (rememberEmail) {
          localStorage.setItem("rememberedEmail", username);
        } else {
          localStorage.removeItem("rememberedEmail");
        }
        navigate("/home");
      } else {
        setLoginError("Credenciais inválidas");
        setLoading(false);
      }
    } catch {
      setLoginError("Algo deu errado");
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    // This function is currently empty
  };

  const toggleRememberEmail = () => {
    setRememberEmail(!rememberEmail);
  };

  return (
    <div className="LoginContainer">
      <Card className="LoginCard">
        <Flex vertical>
          <h2>Entrar</h2>
          {loginError && (
            <Alert
              message={loginError}
              type="error"
              showIcon
              icon={<ExclamationCircleOutlined style={{ color: "red" }} />}
              style={{
                marginBottom: "16px",
              }}
            />
          )}
          <form onSubmit={handleSubmit}>
            <Input
              size="large"
              placeholder="E-mail de usuário"
              prefix={<UserOutlined />}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ marginBottom: "40px", height: 44 }}
              required
            />
            <Input.Password
              size="large"
              placeholder="Insira sua senha"
              prefix={<LockOutlined />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: "24px", height: 44 }}
              required
            />
            <Checkbox
              checked={rememberEmail}
              onChange={toggleRememberEmail}
              style={{ marginBottom: "1.5rem" }}
            >
              Lembrar meu e-mail de usuário
            </Checkbox>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Popover
                content={content}
                trigger="click"
                placement="bottomLeft"
              >
                <Button
                type="link"
                  className="LoginForgottenPass"
                >
                  <InfoCircleOutlined />Esqueci minha senha
                </Button>
              </Popover>

              <Button
                type="primary"
                htmlType="submit"
                className="LoginButton"
                disabled={loading}
              >
                {loading ? <Spin size="small" /> : "Entrar"}
              </Button>
            </div>
          </form>
        </Flex>
      </Card>
    </div>
  );
}

export default Login;
