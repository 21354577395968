import React from "react";
import "./StepTracker.css";
import { CheckOutlined } from "@ant-design/icons";

const StepTracker = ({ currentStep }) => {
  const steps = [
    { number: 1, label: "Informações da fazenda" },
    { number: 2, label: "Upload dos arquivos" },
    { number: 3, label: "Finalização" },
  ];

  return (
    <div className="stepTracker">
      {steps.map((step) => (
        <div
          key={step.number}
          className={`step ${currentStep === step.number ? "stepActive" : ""}`}
        >
          {currentStep > step.number ? (
            <div className="stepNumberChecked">
              <CheckOutlined />
            </div>
          ) : (
            <div className="stepNumber">
              {step.number}
            </div>
          )}
          <div className="stepLabel">{step.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StepTracker;
